import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import StockAnalytics from './StockAnalytics';
import StoreAnalytics from './StoreAnalytics';
import PriceAnalytics from './PriceAnalytics';
import ProductAnalytics from './ProductAnalytics';
import TimeAnalytics from './TimeAnalytics';

const RetailAnalytics = () => {
  // State to track which sections are expanded
  const [expandedSections, setExpandedSections] = useState({
    stock: true,
    store: true,
    price: true,
    product: true,
    time: true
  });

  // Toggle section expanded state
  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  // Section component with collapsible functionality
  const Section = ({ id, title, children }) => (
    <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
      <div 
        className="flex justify-between items-center cursor-pointer"
        onClick={() => toggleSection(id)}
      >
        <h2 className="text-xl font-semibold">{title}</h2>
        <button className="p-1 hover:bg-gray-100 rounded-full transition-colors">
          {expandedSections[id] ? (
            <ChevronUp className="w-6 h-6 text-gray-600" />
          ) : (
            <ChevronDown className="w-6 h-6 text-gray-600" />
          )}
        </button>
      </div>
      
      {/* Collapsible content with smooth transition */}
      <div className={`transition-all duration-300 ease-in-out ${
        expandedSections[id] ? 'max-h-[2000px] opacity-100 mt-4' : 'max-h-0 opacity-0 overflow-hidden'
      }`}>
        {children}
      </div>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-2xl font-bold mb-4">Retail Analytics Dashboard</h1>
        
        {/* Stock Analytics Section */}
        <Section id="stock" title="Stock Overview">
          <StockAnalytics />
        </Section>

        {/* Store Analytics Section */}
        <Section id="store" title="Store Insights">
          <StoreAnalytics />
          {/* <p className="text-gray-500">Store analytics coming soon...</p> */}
        </Section>

        {/* Price Analytics Section */}
        <Section id="price" title="Price Analysis">
          <PriceAnalytics />
          {/* <p className="text-gray-500">Price analytics coming soon...</p> */}
        </Section>

        {/* Product Analytics Section */}
        <Section id="product" title="Product Performance">
          <ProductAnalytics />
          {/* <p className="text-gray-500">Product analytics coming soon...</p> */}
        </Section>

        {/* Time Analytics Section */}
        <Section id="time" title="Time Analysis">
          <TimeAnalytics />
          {/* <p className="text-gray-500">Time analytics coming soon...</p> */}
        </Section>
      </div>
    </div>
  );
};

export default RetailAnalytics;
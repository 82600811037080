import React, { useState, useEffect } from 'react';
import { ref as storageRef, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase';
import { X } from 'lucide-react';

const SignatureModal = ({ imageUrl, onClose }) => {
  if (!imageUrl) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={onClose}>
      <div className="relative bg-white p-4 rounded-lg max-w-2xl w-full mx-4" onClick={e => e.stopPropagation()}>
        <button 
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <X className="h-6 w-6" />
        </button>
        <div className="mt-4">
          <img 
            src={imageUrl} 
            alt="Signature" 
            className="w-full object-contain max-h-[80vh]"
          />
        </div>
      </div>
    </div>
  );
};

const SignatureImage = ({ signatureUrl }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchSignatureUrl = async () => {
      if (!signatureUrl) return;
      try {
        if (signatureUrl.startsWith('http')) {
          setImageUrl(signatureUrl);
        } else {
          const url = await getDownloadURL(storageRef(storage, signatureUrl));
          setImageUrl(url);
        }
      } catch (error) {
        console.error("Error fetching signature URL:", error);
      }
    };

    fetchSignatureUrl();
  }, [signatureUrl]);

  return (
    <>
      {imageUrl ? (
        <img 
          src={imageUrl} 
          alt="Signature" 
          className="w-20 h-10 object-contain cursor-pointer hover:opacity-80 transition-opacity"
          onClick={() => setShowModal(true)}
        />
      ) : (
        <div className="w-20 h-10 bg-gray-100 flex items-center justify-center text-gray-400">
          No signature
        </div>
      )}

      {showModal && (
        <SignatureModal 
          imageUrl={imageUrl} 
          onClose={() => setShowModal(false)} 
        />
      )}
    </>
  );
};

export default SignatureImage;
import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import DatePicker from 'react-datepicker';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Clock, Calendar, Timer, Users } from 'lucide-react';
import "react-datepicker/dist/react-datepicker.css";

const TimeAnalytics = () => {
  // State management
  const [timeMetrics, setTimeMetrics] = useState({
    totalTime: 0,
    totalVisits: 0,
    activeUsers: 0,
    timeDistribution: [],
    userActivity: [],
    visitsByDay: {},
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const reportRef = React.useRef(null);

  // Helper functions
  const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString();
  };

  const formatTime = (minutes) => {
    if (isNaN(minutes) || minutes === null || minutes === 0) return '0h 0m';
    const hours = Math.floor(minutes / 60);
    const mins = Math.round(minutes % 60);
    return `${hours}h ${mins}m`;
  };

  const formatNumber = (number, decimals = 0) => {
    if (number === null || number === undefined || isNaN(number)) {
      return '0';
    }
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    }).format(parseFloat(number));
  };

  const calculateDuration = (endTime, startTime) => {
    if (!endTime || !startTime) return 0;
    const duration = (endTime - startTime) / (1000 * 60);
    return Math.max(0, Math.round(duration));
  };

  const filterDataByDateRange = (data) => {
    if (!startDate || !endDate) return data;
    
    return Object.values(data).filter(item => {
      const itemDate = new Date(item.checkInTime);
      return itemDate >= startDate && itemDate <= endDate;
    });
  };

  const calculateTimeMetrics = (timesheets, users) => {
    const merchandiserActivity = Object.values(timesheets).reduce((acc, timesheet) => {
      if (!timesheet.userId || !timesheet.checkInTime || !timesheet.checkOutTime) return acc;
      
      const date = new Date(timesheet.checkInTime).toLocaleDateString();
      const timeWorked = calculateDuration(timesheet.checkOutTime, timesheet.checkInTime);
      
      if (!acc[timesheet.userId]) {
        acc[timesheet.userId] = {
          daily: {},
          total: 0,
          visits: 0
        };
      }
      
      if (!acc[timesheet.userId].daily[date]) {
        acc[timesheet.userId].daily[date] = 0;
      }
      
      acc[timesheet.userId].daily[date] += timeWorked;
      acc[timesheet.userId].total += timeWorked;
      acc[timesheet.userId].visits += 1;
      
      return acc;
    }, {});

    const userActivity = Object.entries(merchandiserActivity).map(([userId, data]) => {
      const user = users[userId] || {};
      const dailyData = Object.entries(data.daily).map(([date, minutes]) => ({
        date,
        minutes,
        hours: minutes / 60
      }));
      return {
        userId,
        userName: user.fullName || 'Unknown User',
        totalVisits: data.visits,
        totalTime: data.total,
        dailyDistribution: dailyData.sort((a, b) => new Date(a.date) - new Date(b.date))
      };
    }).filter(user => user.totalVisits > 0)
      .sort((a, b) => b.totalTime - a.totalTime);

    const totalTimeMinutes = userActivity.reduce((total, user) => total + user.totalTime, 0);

    const timeDistribution = userActivity.reduce((acc, user) => {
      user.dailyDistribution.forEach(day => {
        const existingDay = acc.find(d => d.date === day.date);
        if (existingDay) {
          existingDay[user.userName] = day.minutes;
        } else {
          acc.push({
            date: day.date,
            [user.userName]: day.minutes,
            ...userActivity.reduce((others, u) => ({
              ...others,
              [u.userName]: u.userName === user.userName ? day.minutes : 0
            }), {})
          });
        }
      });
      return acc;
    }, []).sort((a, b) => new Date(a.date) - new Date(b.date));

    return {
      totalTime: totalTimeMinutes,
      totalVisits: Object.values(timesheets).length,
      activeUsers: userActivity.length,
      timeDistribution,
      userActivity,
      visitsByDay: timeDistribution.reduce((acc, day) => {
        acc[day.date] = Object.values(day).reduce((sum, val) => 
          typeof val === 'number' ? sum + val : sum, 0);
        return acc;
      }, {})
    };
  };

  // Export functions
  const exportToExcel = () => {
    const summaryData = [
      ['Time Analytics Report'],
      [`Period: ${formatDate(startDate)} to ${formatDate(endDate)}`],
      [''],
      ['Metric', 'Value'],
      ['Total Time', formatTime(timeMetrics.totalTime)],
      ['Total Visits', timeMetrics.totalVisits],
      ['Active Users', timeMetrics.activeUsers],
      ['']
    ];

    const userActivityData = timeMetrics.userActivity.map(user => ({
      'User Name': user.userName,
      'Total Visits': user.totalVisits,
      'Total Time': formatTime(user.totalTime)
    }));

    const wb = XLSX.utils.book_new();
    
    const wsSummary = XLSX.utils.aoa_to_sheet(summaryData);
    XLSX.utils.book_append_sheet(wb, wsSummary, "Summary");
    
    const wsUsers = XLSX.utils.json_to_sheet(userActivityData);
    XLSX.utils.book_append_sheet(wb, wsUsers, "User Activity");

    XLSX.writeFile(wb, `time_analytics_${formatDate(startDate)}_to_${formatDate(endDate)}.xlsx`);
  };

  const exportToPDF = async () => {
    if (!reportRef.current) return;

    try {
      const pdf = new jsPDF('p', 'mm', 'a4');
      const canvas = await html2canvas(reportRef.current, {
        scale: 2,
        logging: false,
        useCORS: true
      });

      const imgData = canvas.toDataURL('image/png');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`time_analytics_${formatDate(startDate)}_to_${formatDate(endDate)}.pdf`);
    } catch (err) {
      console.error('Error generating PDF:', err);
    }
  };

  useEffect(() => {
    const db = getDatabase();
    setLoading(true);
    setError(null);

    try {
      const timesheetRef = ref(db, 'timesheets');
      const usersRef = ref(db, 'users');

      Promise.all([
        new Promise(resolve => onValue(timesheetRef, resolve)),
        new Promise(resolve => onValue(usersRef, resolve))
      ]).then(([timesheetSnapshot, usersSnapshot]) => {
        const timesheets = timesheetSnapshot.val() || {};
        const users = usersSnapshot.val() || {};

        const filteredTimesheets = filterDataByDateRange(timesheets);
        const metrics = calculateTimeMetrics(filteredTimesheets, users);
        
        setTimeMetrics(metrics);
        setLoading(false);
      });
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Error fetching data. Please try again later.');
      setLoading(false);
    }
  }, [startDate, endDate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 p-4">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="w-full space-y-8">
      {/* Controls */}
      <div className="flex flex-wrap gap-4 justify-between items-center">
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={setDateRange}
          className="px-4 py-2 border rounded-lg"
          placeholderText="Select date range"
        />
        <div className="flex gap-4">
          <button 
            onClick={exportToExcel}
            className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
          >
            Export to Excel
          </button>
          <button 
            onClick={exportToPDF}
            className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
          >
            Export to PDF
          </button>
        </div>
      </div>

      {/* Report Content */}
      <div ref={reportRef}>
        {/* Overall Metrics */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center gap-2 border-b pb-2">
              <Clock className="w-5 h-5 text-blue-600" />
              <h3 className="text-lg font-bold text-gray-700">Total Time</h3>
            </div>
            <div className="mt-4">
              <div className="text-2xl font-bold text-blue-600">
                {formatTime(timeMetrics.totalTime)}
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center gap-2 border-b pb-2">
              <Calendar className="w-5 h-5 text-purple-600" />
              <h3 className="text-lg font-bold text-gray-700">Total Visits</h3>
            </div>
            <div className="mt-4">
              <div className="text-2xl font-bold text-purple-600">
                {formatNumber(timeMetrics.totalVisits)}
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center gap-2 border-b pb-2">
              <Users className="w-5 h-5 text-orange-600" />
              <h3 className="text-lg font-bold text-gray-700">Active Users</h3>
            </div>
            <div className="mt-4">
              <div className="text-2xl font-bold text-orange-600">
                {formatNumber(timeMetrics.activeUsers)}
              </div>
            </div>
          </div>
        </div>

        {/* Visit Distribution Chart */}
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h3 className="text-lg font-bold text-gray-700 border-b pb-4">Time Distribution by Merchandiser</h3>
          <div className="mt-4 h-80">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={timeMetrics.timeDistribution}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="date"
                  angle={-45}
                  textAnchor="end"
                  height={80}
                  interval={0}
                  tick={{fontSize: 12}}
                />
                <YAxis 
                  label={{ value: 'Minutes', angle: -90, position: 'insideLeft' }}
                />
                <Tooltip 
                  formatter={(value) => {
                    const hours = Math.floor(value / 60);
                    const minutes = value % 60;
                    return `${hours}h ${minutes}m`;
                  }}
                />
                <Legend />
                {timeMetrics.userActivity.map((user, index) => (
                  <Line 
                    key={user.userId}
                    type="monotone" 
                    dataKey={user.userName}
                    stroke={`hsl(${index * (360 / timeMetrics.userActivity.length)}, 70%, 50%)`}
                    strokeWidth={2}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* User Activity Table */}
        <div className="bg-white rounded-lg shadow-md p-6 text-left">
          <h3 className="text-lg font-bold text-gray-700 border-b pb-4">Merchandiser Activity</h3>
          <div className="mt-4 overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr className="bg-gray-50">
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Merchandiser</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Total Visits</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Total Time</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Avg Time/Visit</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Avg Visits/Day</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {timeMetrics.userActivity.map((user, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">{user.userName}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{formatNumber(user.totalVisits)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{formatTime(user.totalTime)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {formatTime(user.totalTime / (user.totalVisits || 1))}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {formatNumber(user.totalVisits / (user.dailyDistribution.length || 1), 1)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeAnalytics;
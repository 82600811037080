import React, { useState, useEffect } from 'react';
import { ref, onValue, off } from 'firebase/database';
import { db } from '../firebase';
import { format, isValid, parse } from 'date-fns';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

// Utility functions
const isValidYear = (year) => {
  return year >= 2000 && year <= 2100;
};

const formatDateTime = (timestamp) => {
  if (!timestamp) return 'N/A';
  try {
    // Handle different timestamp formats
    let date;
    if (typeof timestamp === 'number') {
      // Convert seconds to milliseconds if needed
      const milliseconds = timestamp.toString().length === 10 ? timestamp * 1000 : timestamp;
      date = new Date(milliseconds);
    } else if (timestamp.seconds) {
      date = new Date(timestamp.seconds * 1000);
    } else if (timestamp._seconds) {
      date = new Date(timestamp._seconds * 1000);
    } else {
      date = new Date(timestamp);
    }

    // Validate the date
    if (!isValid(date) || !isValidYear(date.getFullYear())) {
      console.error('Invalid date or year:', timestamp);
      return 'Invalid Date';
    }

    return format(date, 'MMM d, yyyy h:mma').replace(':00', '');
  } catch (error) {
    console.error('Error formatting date:', error, timestamp);
    return 'Date Error';
  }
};

const normalizeTimestamp = (timestamp) => {
  if (!timestamp) return null;
  try {
    // Handle different timestamp formats
    if (typeof timestamp === 'number') {
      // Convert seconds to milliseconds if needed
      const milliseconds = timestamp.toString().length === 10 ? timestamp * 1000 : timestamp;
      const date = new Date(milliseconds);
      return isValid(date) && isValidYear(date.getFullYear()) ? date : null;
    }
    return null;
  } catch (error) {
    console.error('Error normalizing timestamp:', error);
    return null;
  }
};

const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
  <button
    className="w-full px-4 py-2 text-left bg-white border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
    onClick={onClick}
    ref={ref}
  >
    {value || "Select Date Range"}
  </button>
));

CustomDateInput.displayName = 'CustomDateInput';

const OutOfStock = () => {
  // State Management
  const [outOfStockItems, setOutOfStockItems] = useState([]);
  const [categories, setCategories] = useState({});
  const [products, setProducts] = useState({});
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Filter States
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filteredLocations, setFilteredLocations] = useState([]);

  useEffect(() => {
    const categoriesRef = ref(db, 'categories');
    const productsRef = ref(db, 'products');
    const outOfStockRef = ref(db, 'outOfStock');
    const storesRef = ref(db, 'stores');

    const fetchData = async () => {
      try {
        // Fetch categories
        onValue(categoriesRef, (snapshot) => {
          if (snapshot.exists()) {
            setCategories(snapshot.val());
          }
        });

        // Fetch products
        onValue(productsRef, (snapshot) => {
          if (snapshot.exists()) {
            setProducts(snapshot.val());
          }
        });

        // Fetch stores
        onValue(storesRef, (snapshot) => {
          if (snapshot.exists()) {
            const storesData = Object.entries(snapshot.val()).map(([id, data]) => ({
              id,
              ...data
            }));
            setStores(storesData);
          }
        });

        // Fetch out of stock records
        onValue(outOfStockRef, (snapshot) => {
          if (snapshot.exists()) {
            const items = Object.entries(snapshot.val())
              .map(([id, data]) => ({
                id,
                ...data,
                normalizedTimestamp: normalizeTimestamp(data.timestamp)
              }))
              .filter(item => item.normalizedTimestamp !== null) // Filter out items with invalid dates
              .sort((a, b) => b.normalizedTimestamp - a.normalizedTimestamp);
            setOutOfStockItems(items);
          }
          setLoading(false);
        });
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      off(categoriesRef);
      off(productsRef);
      off(outOfStockRef);
      off(storesRef);
    };
  }, []);

  // Update filtered locations when store is selected
  useEffect(() => {
    if (!selectedStore) {
      setFilteredLocations([...new Set(stores.map(store => store.searchLocation))]);
      setSelectedLocation(null);
    } else {
      const storeLocations = stores
        .filter(store => store.id === selectedStore.value)
        .map(store => store.searchLocation);
      setFilteredLocations(storeLocations);
      setSelectedLocation(null);
    }
  }, [selectedStore, stores]);

  // Filter Data
  const filteredData = outOfStockItems.filter(item => {
    const matchesDate = !startDate || !endDate || 
      (item.normalizedTimestamp >= startDate && item.normalizedTimestamp <= endDate);
    const matchesStore = !selectedStore || item.storeId === selectedStore.value;
    const store = stores.find(s => s.id === item.storeId);
    const matchesLocation = !selectedLocation || 
      (store && store.searchLocation === selectedLocation.value);
    const matchesCategory = !selectedCategory || item.categoryId === selectedCategory.value;
    const matchesProduct = !selectedProduct || item.productId === selectedProduct.value;

    return matchesDate && matchesStore && matchesLocation && 
           matchesCategory && matchesProduct;
  });

  // Calculate summary metrics
  const calculateSummaryMetrics = (data) => {
    const uniqueStores = new Set(data.map(item => item.storeId));
    const uniqueCategories = new Set(data.map(item => item.categoryId));
    const uniqueProducts = new Set(data.map(item => item.productId));

    return {
      stores: {
        outOfStock: uniqueStores.size,
        total: stores.length
      },
      categories: {
        outOfStock: uniqueCategories.size,
        total: Object.keys(categories).length
      },
      products: {
        outOfStock: uniqueProducts.size,
        total: Object.keys(products).length
      }
    };
  };

  // Export Functions
  const exportToExcel = () => {
    const exportData = filteredData.map(item => {
      const store = stores.find(s => s.id === item.storeId);
      return {
        'Date & Time': formatDateTime(item.timestamp),
        'Store': store ? store.storeName : 'Unknown Store',
        'Location': store ? store.searchLocation : 'Unknown Location',
        'Category': categories[item.categoryId]?.categoryName || 'Unknown Category',
        'Product': products[item.productId]?.productName || 'Unknown Product',
        'SKU': item.sku,
        'Reason': item.reason
      };
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(exportData);
    XLSX.utils.book_append_sheet(wb, ws, 'Out of Stock Items');
    XLSX.writeFile(wb, 'out_of_stock_report.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    
    const tableData = filteredData.map(item => {
      const store = stores.find(s => s.id === item.storeId);
      return [
        formatDateTime(item.timestamp),
        store ? store.storeName : 'Unknown Store',
        categories[item.categoryId]?.categoryName || 'Unknown Category',
        products[item.productId]?.productName || 'Unknown Product',
        item.sku,
        item.reason
      ];
    });

    doc.autoTable({
      head: [['Date & Time', 'Store', 'Category', 'Product', 'SKU', 'Reason']],
      body: tableData,
      startY: 20,
      theme: 'grid'
    });

    doc.save('out_of_stock_report.pdf');
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center p-4">{error}</div>
    );
  }

  const metrics = calculateSummaryMetrics(filteredData);

  return (
    <div className="container mx-auto px-4 py-8 text-left">
      <div className="bg-white rounded-lg shadow">
        <div className="p-6">
          <h1 className="text-2xl font-bold mb-6">Out of Stock Items</h1>

          {/* Filters */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
            <div className="col-span-full md:col-span-1">
              <DatePicker
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={setDateRange}
                customInput={<CustomDateInput />}
                placeholderText="Select Date Range"
                className="w-full"
              />
            </div>

            <Select
              value={selectedStore}
              onChange={setSelectedStore}
              options={stores.map(store => ({
                value: store.id,
                label: store.storeName
              }))}
              placeholder="Select Store"
              isClearable
              className="react-select-container"
            />

            <Select
              value={selectedLocation}
              onChange={setSelectedLocation}
              options={filteredLocations.map(location => ({
                value: location,
                label: location
              }))}
              placeholder="Select Location"
              isClearable
              isDisabled={!selectedStore}
              className="react-select-container"
            />

            <Select
              value={selectedCategory}
              onChange={setSelectedCategory}
              options={Object.entries(categories).map(([id, category]) => ({
                value: id,
                label: category.categoryName
              }))}
              placeholder="Select Category"
              isClearable
              className="react-select-container"
            />

            <Select
              value={selectedProduct}
              onChange={setSelectedProduct}
              options={Object.entries(products).map(([id, product]) => ({
                value: id,
                label: product.productName
              }))}
              placeholder="Select Product"
              isClearable
              className="react-select-container"
            />
          </div>

          {/* Summary Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
            <div className="bg-white p-4 rounded-lg shadow border">
              <h3 className="text-lg font-semibold mb-2">Stores Out of Stock</h3>
              <p className="text-2xl font-bold">
                {metrics.stores.outOfStock}/{metrics.stores.total}
              </p>
            </div>

            <div className="bg-white p-4 rounded-lg shadow border">
              <h3 className="text-lg font-semibold mb-2">Categories Out of Stock</h3>
              <p className="text-2xl font-bold">
                {metrics.categories.outOfStock}/{metrics.categories.total}
              </p>
            </div>

            <div className="bg-white p-4 rounded-lg shadow border">
              <h3 className="text-lg font-semibold mb-2">Products Out of Stock</h3>
              <p className="text-2xl font-bold">
                {metrics.products.outOfStock}/{metrics.products.total}
              </p>
            </div>
          </div>

          {/* Export Buttons */}
          <div className="flex gap-4 mb-6">
            <button
              onClick={exportToExcel}
              className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded transition-colors duration-200"
            >
              Export to Excel
            </button>
            <button
              onClick={exportToPDF}
              className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition-colors duration-200"
            >
              Export to PDF
            </button>
          </div>

          {/* Data Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Product Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Category
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Store
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Location
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    SKU
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Reason
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Report Date
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredData.map((item) => {
                  const store = stores.find(s => s.id === item.storeId);
                  const category = categories[item.categoryId];
                  const product = products[item.productId];
                  
                  if (!category || !product) return null;
                  
                  return (
                    <tr key={item.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {product.productName}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                          {category.categoryName}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {store ? store.storeName : 'Unknown Store'}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {store ? store.searchLocation : 'Unknown Location'}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {item.sku}
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-gray-900">
                          {item.reason}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">
                          {formatDateTime(item.timestamp)}
                        </div>
                      </td>
                    </tr>
                  );
                })}
                {filteredData.length === 0 && (
                  <tr>
                    <td colSpan="7" className="text-center py-8 text-gray-500">
                      No out of stock items reported.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutOfStock;
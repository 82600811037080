import React, { useState, useEffect } from 'react';
import { 
  getDatabase, 
  ref, 
  onValue,
  remove 
} from 'firebase/database';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import SmerchLogo from '../assets/SmerchLogo.png';
import Select from 'react-select';

const ComplianceBadge = ({ status }) => {
  const statusClasses = {
    'Compliant': "bg-green-100 text-green-800",
    'Non-Compliant': "bg-red-100 text-red-800",
    'Partially Compliant': "bg-yellow-100 text-yellow-800"
  };

  return (
    <span className={`px-2 py-1 rounded-full text-sm font-medium ${statusClasses[status]}`}>
      {status}
    </span>
  );
};

const ImageModal = ({ imageUrl, onClose }) => {
  if (!imageUrl) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg max-w-4xl w-full">
        <div className="p-4 border-b flex justify-between items-center">
          <h3 className="text-lg font-semibold">Image Preview</h3>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>
        <div className="p-4">
          <img 
            src={imageUrl} 
            alt="Planogram" 
            className="w-full h-auto rounded"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "placeholder-image-url.jpg";
            }}
          />
        </div>
      </div>
    </div>
  );
};

const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => {
  return (
    <button 
      className="w-full px-4 py-2 text-left bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500" 
      onClick={onClick} 
      ref={ref}
    >
      {value || "Select Date Range"}
    </button>
  );
});

CustomDateInput.displayName = 'CustomDateInput';

const PlanogramComplianceTracker = () => {
  // State declarations
  const [planogramData, setPlanogramData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [stores, setStores] = useState([]);
  const [products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Filter states
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedStore, setSelectedStore] = useState('all');
  const [selectedLocation, setSelectedLocation] = useState('all');
  const [selectedMerchandiser, setSelectedMerchandiser] = useState('all');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedProduct, setSelectedProduct] = useState('all');
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  // Initial data fetch
  useEffect(() => {
    const db = getDatabase();
    
    const fetchData = () => {
      // Fetch categories
      onValue(ref(db, 'categories'), (snapshot) => {
        if (snapshot.exists()) {
          const categoriesData = Object.entries(snapshot.val()).map(([id, data]) => ({
            id,
            name: data.categoryName
          }));
          setCategories(categoriesData);
        }
      }, (error) => {
        console.error('Error fetching categories:', error);
        setError('Error loading categories');
      });

      // Fetch stores
      onValue(ref(db, 'stores'), (snapshot) => {
        if (snapshot.exists()) {
          const storesData = Object.entries(snapshot.val()).map(([id, data]) => ({
            id,
            name: data.storeName,
            location: data.searchLocation,
            parish: data.parish,
            channel: data.channel
          }));
          setStores(storesData);
        }
      }, (error) => {
        console.error('Error fetching stores:', error);
        setError('Error loading stores');
      });

      // Fetch products
      onValue(ref(db, 'products'), (snapshot) => {
        if (snapshot.exists()) {
          const productsData = Object.entries(snapshot.val()).map(([id, data]) => ({
            id,
            name: data.productName,
            productNumber: data.productNumber,
            categoryId: data.categoryId
          }));
          setProducts(productsData);
        }
      }, (error) => {
        console.error('Error fetching products:', error);
        setError('Error loading products');
      });

      // Fetch users
      onValue(ref(db, 'users'), (snapshot) => {
        if (snapshot.exists()) {
          const usersData = Object.entries(snapshot.val()).map(([id, data]) => ({
            id,
            name: data.fullName
          }));
          setUsers(usersData);
        }
      }, (error) => {
        console.error('Error fetching users:', error);
        setError('Error loading users');
      });
    };

    fetchData();
  }, []);

  // Fetch planogram data separately
  useEffect(() => {
    const db = getDatabase();
    
    const fetchPlanogramData = () => {
      onValue(ref(db, 'planograms'), (snapshot) => {
        if (snapshot.exists()) {
          const planogramsData = Object.entries(snapshot.val()).map(([id, data]) => {
            const product = products.find(p => p.id === data.productId);
            
            let formattedTimestamp = 'Unknown';
            if (data.timestamp) {
              const timestamp = typeof data.timestamp === 'number' 
                ? data.timestamp 
                : data.timestamp.seconds || data.timestamp._seconds;
              formattedTimestamp = format(new Date(timestamp), 'MMM d, yyyy hh:mm a');
            }

            return {
              id,
              ...data,
              timestamp: formattedTimestamp,
              productNumber: product?.productNumber || 'Unknown'
            };
          });
          setPlanogramData(planogramsData);
        }
        setLoading(false);
      }, (error) => {
        console.error('Error fetching planograms:', error);
        setError('Error loading planogram data');
        setLoading(false);
      });
    };

    if (products.length > 0) {
      fetchPlanogramData();
    }
  }, [products]);

  // Update filtered locations when store is selected
  useEffect(() => {
    if (selectedStore === 'all') {
      setFilteredLocations([...new Set(stores.map(store => store.location))]);
      setSelectedLocation('all');
    } else {
      const storeLocations = stores
        .filter(store => store.id === selectedStore)
        .map(store => store.location);
      setFilteredLocations(storeLocations);
      if (!storeLocations.includes(selectedLocation)) {
        setSelectedLocation('all');
      }
    }
  }, [selectedStore, stores, selectedLocation]);

  // Update filtered products when category is selected
  useEffect(() => {
    if (selectedCategory === 'all') {
      setFilteredProducts(products);
      setSelectedProduct('all');
    } else {
      const categoryProducts = products.filter(product => 
        product.categoryId === selectedCategory
      );
      setFilteredProducts(categoryProducts);
      if (!categoryProducts.find(p => p.id === selectedProduct)) {
        setSelectedProduct('all');
      }
    }
  }, [selectedCategory, products, selectedProduct]);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this entry?')) {
      const db = getDatabase();
      try {
        await remove(ref(db, `planograms/${id}`));
      } catch (error) {
        console.error('Error deleting entry:', error);
        setError('Error deleting entry');
      }
    }
  };

  const exportToExcel = () => {
    const exportData = filteredData.map(item => ({
      'Date': item.timestamp,
      'Store': getStoreName(item.storeId),
      'Location': getStoreLocation(item.storeId),
      'Category': getCategoryName(item.categoryId),
      'Product': item.productName,
      'SKU': item.productNumber,
      'Is Planogram Compliant?': item.compliance,
      'Action': item.action,
      'Merchandiser': getUserName(item.uploadedBy)
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Planogram Data');
    
    try {
      if (ws) {
        const logoImg = new Image();
        logoImg.src = SmerchLogo;
        
        ws['!images'] = [{
          name: 'SmerchLogo',
          data: logoImg.src,
          position: {
            type: 'twoCellAnchor',
            from: { col: 0, row: 0 },
            to: { col: 2, row: 2 }
          }
        }];
      }
    } catch (error) {
      console.error('Error adding logo to Excel:', error);
    }
    
    XLSX.writeFile(wb, 'planogram_compliance_report.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF('l', 'pt', 'legal');
    
    doc.addImage(SmerchLogo, 'PNG', 40, 40, 100, 50);
    
    const tableColumn = [
      'Date', 'Store', 'Location', 'Category', 'Product', 
      'SKU', 'Is Planogram Compliant?', 'Action', 'Merchandiser'
    ];
    
    const tableRows = filteredData.map(item => [
      item.timestamp,
      getStoreName(item.storeId),
      getStoreLocation(item.storeId),
      getCategoryName(item.categoryId),
      item.productName,
      item.productNumber,
      item.compliance,
      item.action,
      getUserName(item.uploadedBy)
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 100,
      theme: 'grid',
      styles: {
        cellPadding: 2,
        fontSize: 10,
        overflow: 'linebreak',
        cellWidth: 'wrap'
      },
      columnStyles: {
        0: { cellWidth: 80 },
        1: { cellWidth: 80 },
        2: { cellWidth: 80 },
        3: { cellWidth: 80 },
        4: { cellWidth: 100 },
        5: { cellWidth: 60 },
        6: { cellWidth: 80 },
        7: { cellWidth: 100 },
        8: { cellWidth: 80 }
      }
    });

    doc.save('planogram_compliance_report.pdf');
  };

  // Helper functions
  const getStoreName = (storeId) => 
    stores.find(store => store.id === storeId)?.name || 'Unknown';
  
  const getStoreLocation = (storeId) => 
    stores.find(store => store.id === storeId)?.location || 'Unknown';
  
  const getCategoryName = (categoryId) => 
    categories.find(category => category.id === categoryId)?.name || 'Unknown';
  
  const getUserName = (item) => item.userName || 'Unknown';

  // Filter data
  const filteredData = planogramData.filter(item => {
    const itemDate = new Date(item.timestamp);
    const matchesDateRange = !startDate || !endDate || 
      (itemDate >= startDate && itemDate <= endDate);
    const matchesStore = selectedStore === 'all' || item.storeId === selectedStore;
    const matchesLocation = selectedLocation === 'all' || 
      getStoreLocation(item.storeId) === selectedLocation;
    const matchesMerchandiser = selectedMerchandiser === 'all' || 
      item.uploadedBy === selectedMerchandiser;
    const matchesCategory = selectedCategory === 'all' || 
      item.categoryId === selectedCategory;
    const matchesProduct = selectedProduct === 'all' || 
      item.productId === selectedProduct;

    return matchesDateRange && matchesStore && matchesLocation && 
           matchesMerchandiser && matchesCategory && matchesProduct;
  });

  const handleImageClick = (imageUrl) => {
    if (imageUrl) {
      window.open(imageUrl, '_blank');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-red-50 border-l-4 border-red-500 p-4">
          <p className="text-red-700">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow">
        <div className="p-6">
          <h1 className="text-2xl font-bold mb-6">Planogram Compliance Tracker</h1>
          
          {/* Filters */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
            <div className="lg:col-span-4">
              <DatePicker
                selected={startDate}
                onChange={(dates) => setDateRange(dates)}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                customInput={<CustomDateInput />}
                formatWeekDay={nameOfDay => nameOfDay.substr(0,3)}
                monthsShown={2}
                className="w-full"
                popperClassName="react-datepicker-right"
              />
            </div>

<div>
  <Select
    value={selectedStore !== 'all' ? { value: selectedStore, label: getStoreName(selectedStore) } : null}
    onChange={(option) => setSelectedStore(option ? option.value : 'all')}
    options={stores.map(store => ({
      value: store.id,
      label: store.name
    }))}
    isClearable
    isSearchable
    placeholder="Select Store"
    className="react-select-container"
    classNamePrefix="react-select"
  />
</div>

<div>
  <Select
    value={selectedLocation !== 'all' ? { value: selectedLocation, label: selectedLocation } : null}
    onChange={(option) => setSelectedLocation(option ? option.value : 'all')}
    options={filteredLocations.map(location => ({
      value: location,
      label: location
    }))}
    isClearable
    isSearchable
    placeholder="Select Location"
    isDisabled={!selectedStore || selectedStore === 'all'}
    className="react-select-container"
    classNamePrefix="react-select"
  />
</div>

<div>
  <Select
    value={selectedMerchandiser !== 'all' ? { value: selectedMerchandiser, label: getUserName(selectedMerchandiser) } : null}
    onChange={(option) => setSelectedMerchandiser(option ? option.value : 'all')}
    options={users.map(user => ({
      value: user.id,
      label: user.name
    }))}
    isClearable
    isSearchable
    placeholder="Select Merchandiser"
    className="react-select-container"
    classNamePrefix="react-select"
  />
</div>

<div>
  <Select
    value={selectedCategory !== 'all' ? { value: selectedCategory, label: getCategoryName(selectedCategory) } : null}
    onChange={(option) => setSelectedCategory(option ? option.value : 'all')}
    options={categories.map(category => ({
      value: category.id,
      label: category.name
    }))}
    isClearable
    isSearchable
    placeholder="Select Category"
    className="react-select-container"
    classNamePrefix="react-select"
  />
</div>

<div>
  <Select
    value={selectedProduct !== 'all' ? { 
      value: selectedProduct, 
      label: filteredProducts.find(p => p.id === selectedProduct)?.name 
    } : null}
    onChange={(option) => setSelectedProduct(option ? option.value : 'all')}
    options={filteredProducts.map(product => ({
      value: product.id,
      label: product.name
    }))}
    isClearable
    isSearchable
    placeholder="Select Product"
    isDisabled={!selectedCategory || selectedCategory === 'all'}
    className="react-select-container"
    classNamePrefix="react-select"
  />
</div>
</div>

{/* Export Buttons */}
<div className="flex gap-4 mb-6">
<button
  onClick={exportToExcel}
  className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded transition-colors duration-200"
>
  Export to Excel
</button>
<button
  onClick={exportToPDF}
  className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition-colors duration-200"
>
  Export to PDF
</button>
</div>

{/* Data Table */}
<div className="overflow-x-auto rounded-lg border border-gray-200">
<table className="min-w-full divide-y divide-gray-200">
  <thead className="bg-gray-50">
    <tr>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Store</th>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product</th>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">SKU</th>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Is Planogram Compliant?</th>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Before Photo</th>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">After Photo</th>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Merchandiser</th>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
    </tr>
  </thead>
  <tbody className="bg-white divide-y divide-gray-200">
    {filteredData.length === 0 ? (
      <tr>
        <td colSpan="12" className="px-6 py-4 text-center text-gray-500">
          No records found matching the selected filters
        </td>
      </tr>
    ) : (
      filteredData.map((item) => (
        <tr key={item.id} className="hover:bg-gray-50">
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.timestamp}</td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{getStoreName(item.storeId)}</td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{getStoreLocation(item.storeId)}</td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{getCategoryName(item.categoryId)}</td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.productName}</td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.productNumber}</td>
          <td className="px-6 py-4 whitespace-nowrap">
            <ComplianceBadge status={item.compliance} />
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.action}</td>
          <td className="px-6 py-4 whitespace-nowrap">
            {item.beforePhotoUrl && (
              <img 
                src={item.beforePhotoUrl} 
                alt="Before" 
                className="w-16 h-16 object-cover rounded cursor-pointer hover:opacity-75 transition-opacity"
                onClick={() => handleImageClick(item.beforePhotoUrl)}
              />
            )}
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            {item.afterPhotoUrl && (
              <img 
                src={item.afterPhotoUrl} 
                alt="After" 
                className="w-16 h-16 object-cover rounded cursor-pointer hover:opacity-75 transition-opacity"
                onClick={() => handleImageClick(item.afterPhotoUrl)}
              />
            )}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{getUserName(item)}</td>
          <td className="px-6 py-4 whitespace-nowrap">
            <button 
              onClick={() => handleDelete(item.id)}
              className="text-red-600 hover:text-red-800 hover:underline focus:outline-none focus:ring-2 focus:ring-red-500 rounded"
            >
              Delete
            </button>
          </td>
        </tr>
      ))
    )}
  </tbody>
</table>
</div>
</div>
</div>

{selectedImage && (
<ImageModal 
imageUrl={selectedImage} 
onClose={() => setSelectedImage(null)} 
/>
)}
</div>
);
};

export default PlanogramComplianceTracker;  
import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import DatePicker from 'react-datepicker';
import { Building, Store, Fuel, Pill } from 'lucide-react';
import "react-datepicker/dist/react-datepicker.css";

const StoreAnalytics = () => {
  // State management
  const [storeMetrics, setStoreMetrics] = useState({
    servicedStores: 0,
    outOfStockStores: 0,
    expiredProductStores: 0,
    storesByChannel: {
      Supermarket: 0,
      Wholesale: 0,
      'Gas Station': 0,
      Pharmacy: 0,
      Bar: 0
    }
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [stores, setStores] = useState([]);
  const [timesheetData, setTimesheetData] = useState([]);
  const [outOfStockData, setOutOfStockData] = useState([]);
  const [expiryData, setExpiryData] = useState([]);

  // Helper function to format dates
  const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString();
  };

  // Calculate serviced stores
  const calculateServicedStores = (timesheet) => {
    return new Set(timesheet
      .filter(record => record.status === "completed")
      .map(record => record.storeId)
    ).size;
  };

  // Calculate out of stock stores
  const calculateOutOfStockStores = (outOfStock) => {
    return new Set(outOfStock.map(item => item.storeId)).size;
  };

  // Calculate stores with expired products
  const calculateExpiredStores = (expiry) => {
    return new Set(
      expiry
        .filter(item => item.status === 'Expired')
        .map(item => item.storeId)
    ).size;
  };

  // Filter data based on date range
  const filterDataByDateRange = (data) => {
    if (!startDate || !endDate) return data;
    
    return data.filter(item => {
      const itemDate = new Date(item.timestamp);
      return itemDate >= startDate && itemDate <= endDate;
    });
  };

  // Fetch data from Firebase
  useEffect(() => {
    const db = getDatabase();
    setLoading(true);
    setError(null);

    try {
      // Fetch stores
      const storesRef = ref(db, 'stores');
      const timesheetRef = ref(db, 'timesheets');
      const outOfStockRef = ref(db, 'outOfStock');
      const expiryRef = ref(db, 'productExpiry');

      // Fetch all data simultaneously
      Promise.all([
        new Promise(resolve => onValue(storesRef, resolve)),
        new Promise(resolve => onValue(timesheetRef, resolve)),
        new Promise(resolve => onValue(outOfStockRef, resolve)),
        new Promise(resolve => onValue(expiryRef, resolve))
      ]).then(([storesSnapshot, timesheetSnapshot, outOfStockSnapshot, expirySnapshot]) => {
        // Process stores data
        const storesData = storesSnapshot.val() || {};
        const storesList = Object.values(storesData);
        setStores(storesList);

        // Process timesheet data
        const timesheetData = timesheetSnapshot.val() || {};
        const timesheetList = Object.values(timesheetData);
        const filteredTimesheet = filterDataByDateRange(timesheetList);
        setTimesheetData(filteredTimesheet);

        // Process out of stock data
        const outOfStockData = outOfStockSnapshot.val() || {};
        const outOfStockList = Object.values(outOfStockData);
        const filteredOutOfStock = filterDataByDateRange(outOfStockList);
        setOutOfStockData(filteredOutOfStock);

        // Process expiry data
        const expiryData = expirySnapshot.val() || {};
        const expiryList = Object.values(expiryData);
        const filteredExpiry = filterDataByDateRange(expiryList);
        setExpiryData(filteredExpiry);

        // Calculate metrics
        const storesByChannel = storesList.reduce((acc, store) => {
          acc[store.channel] = (acc[store.channel] || 0) + 1;
          return acc;
        }, {
          Supermarket: 0,
          Wholesale: 0,
          Bar: 0,
          Pharmacy: 0
        });

        setStoreMetrics({
          servicedStores: calculateServicedStores(filteredTimesheet),
          outOfStockStores: calculateOutOfStockStores(filteredOutOfStock),
          expiredProductStores: calculateExpiredStores(filteredExpiry),
          storesByChannel
        });

        setLoading(false);
      });
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Error fetching data. Please try again later.');
      setLoading(false);
    }
  }, [startDate, endDate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 p-4">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="w-full space-y-8">
      {/* Date Range Selector */}
      <div className="flex flex-wrap gap-4 items-center">
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={setDateRange}
          className="px-4 py-2 border rounded-lg"
          placeholderText="Select date range"
        />
      </div>

      {/* Store Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="border-b pb-2">
            <h3 className="text-lg font-bold text-gray-700">Stores Serviced</h3>
          </div>
          <div className="mt-4">
            <div className="text-2xl font-bold text-blue-600">
              {storeMetrics.servicedStores}
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="border-b pb-2">
            <h3 className="text-lg font-bold text-gray-700">Stores Out of Stock</h3>
          </div>
          <div className="mt-4">
            <div className="text-2xl font-bold text-red-600">
              {storeMetrics.outOfStockStores}
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="border-b pb-2">
            <h3 className="text-lg font-bold text-gray-700">Stores with Expired Products</h3>
          </div>
          <div className="mt-4">
            <div className="text-2xl font-bold text-orange-600">
              {storeMetrics.expiredProductStores}
            </div>
          </div>
        </div>
      </div>

      {/* Store Types */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="border-b pb-2 flex items-center gap-2">
            <Building className="w-5 h-5 text-blue-600" />
            <h3 className="text-lg font-bold text-gray-700">Supermarket</h3>
          </div>
          <div className="mt-4">
            <div className="text-2xl font-bold text-blue-600">
              {storeMetrics.storesByChannel.Supermarket}
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="border-b pb-2 flex items-center gap-2">
            <Store className="w-5 h-5 text-green-600" />
            <h3 className="text-lg font-bold text-gray-700">Wholesale</h3>
          </div>
          <div className="mt-4">
            <div className="text-2xl font-bold text-green-600">
              {storeMetrics.storesByChannel.Wholesale}
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="border-b pb-2 flex items-center gap-2">
            <Fuel className="w-5 h-5 text-purple-600" />
            <h3 className="text-lg font-bold text-gray-700">Bar</h3>
          </div>
          <div className="mt-4">
            <div className="text-2xl font-bold text-purple-600">
              {storeMetrics.storesByChannel.Bar}
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="border-b pb-2 flex items-center gap-2">
            <Pill className="w-5 h-5 text-orange-600" />
            <h3 className="text-lg font-bold text-gray-700">Pharmacy</h3>
          </div>
          <div className="mt-4">
            <div className="text-2xl font-bold text-orange-600">
              {storeMetrics.storesByChannel.Pharmacy}
            </div>
          </div>
        </div>
      </div>

      {/* Store Distribution Chart */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="border-b pb-4">
          <h3 className="text-lg font-bold text-gray-700">Store Distribution by Channel</h3>
        </div>
        <div className="mt-4 h-80">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={Object.entries(storeMetrics.storesByChannel).map(([channel, count]) => ({
              channel,
              count
            }))}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="channel" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#3b82f6" name="Stores" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default StoreAnalytics;
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import { CSVLink } from "react-csv";
import { ref, onValue, remove } from 'firebase/database';
import { db } from '../firebase';
import "react-datepicker/dist/react-datepicker.css";

const OpportunityThreat = () => {
  const defaultDateRange = [new Date(), new Date()];
  const [filters, setFilters] = useState({
    dateRange: defaultDateRange,
    type: null,
    store: null,
    location: null, 
    category: null,
    product: null,
    merchandiser: null
  });

  const [opportunityData, setOpportunityData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [stores, setStores] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const types = [
    { value: 'Opportunity', label: 'Opportunity' },
    { value: 'Threat', label: 'Threat' }
  ];

  useEffect(() => {
    const loadStores = () => {
      const storesRef = ref(db, 'stores');
      onValue(storesRef, (snapshot) => {
        const data = snapshot.val();
        const storesList = data ? Object.entries(data).map(([id, store]) => ({
          value: id,
          label: store.storeName,
          parish: store.parish,
          searchLocation: store.searchLocation
        })) : [];
        setStores(storesList);
      });
    };

    const loadCategories = () => {
      const categoriesRef = ref(db, 'categories');
      onValue(categoriesRef, (snapshot) => {
        const data = snapshot.val();
        const categoriesList = data ? Object.entries(data).map(([id, category]) => ({
          value: id,
          label: category.categoryName
        })) : [];
        setCategories(categoriesList);
      });
    };

    const loadProducts = () => {
      const productsRef = ref(db, 'products');
      onValue(productsRef, (snapshot) => {
        const data = snapshot.val();
        const productsList = data ? Object.entries(data).map(([id, product]) => ({
          value: id,
          label: product.productName,
          categoryId: product.categoryId,
          productNumber: product.productNumber
        })) : [];
        setProducts(productsList);
      });
    };

    const loadUsers = () => {
      const usersRef = ref(db, 'users');
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
        const usersList = data ? Object.entries(data).map(([id, user]) => ({
          value: id,
          label: user.fullName,
          role: user.role
        })).filter(user => user.role === 'Merchandiser and Manager') : [];
        setUsers(usersList);
      });
    };

    Promise.all([
      loadStores(),
      loadCategories(),
      loadProducts(), 
      loadUsers()
    ]).then(() => setLoading(false));
  }, []);

  useEffect(() => {
    const opportunityRef = ref(db, 'opportunities');
    onValue(opportunityRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const opportunitiesList = Object.entries(data).map(([id, opportunity]) => {
          const store = stores.find(s => s.value === opportunity.storeId);
          const category = categories.find(c => c.value === opportunity.categoryId);
          const product = products.find(p => p.value === opportunity.productId);
          const merchandiser = users.find(u => u.value === opportunity.userId);
          
          return {
            id,
            timestamp: opportunity.timestamp,
            date: new Date(opportunity.timestamp).toLocaleDateString(),
            type: opportunity.type || '',
            storeName: store?.label || opportunity.storeName || '',
            location: store?.parish || opportunity.location || '',
            categoryName: category?.label || opportunity.categoryName || '',
            productName: product?.label || opportunity.productName || '',
            productNumber: opportunity.productNumber || '',
            description: opportunity.description || '',
            photoUrl: opportunity.photoUrl || '',
            merchandiserName: merchandiser?.label || opportunity.userEmail || ''
          };
        });
        setOpportunityData(opportunitiesList);
      }
      setLoading(false);
    });
  }, [categories, products, stores, users]);

  const handleStoreChange = (selectedStore) => {
    setFilters(prev => ({ ...prev, store: selectedStore, location: null }));
    if (selectedStore) {
      const storeLocations = stores
        .filter(store => store.value === selectedStore.value)
        .map(store => ({
          value: store.parish,
          label: store.parish
        }));
      setFilteredLocations(storeLocations);
    } else {
      setFilteredLocations([]);
    }
  };

  const handleDelete = async (id) => {
    try {
      if (window.confirm('Are you sure you want to delete this record?')) {
        setIsUploading(true);
        const opportunityRef = ref(db, `opportunities/${id}`);
        await remove(opportunityRef);
        setIsUploading(false);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
      alert('Failed to delete record. Please try again.');
      setIsUploading(false);
    }
  };

  const getFilteredData = () => {
    return opportunityData.filter(row => {
      try {
        const rowDate = new Date(row.timestamp);
        const startDate = new Date(filters.dateRange[0] || defaultDateRange[0]);
        const endDate = new Date(filters.dateRange[1] || defaultDateRange[1]);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        
        const dateInRange = rowDate >= startDate && rowDate <= endDate;
        const typeMatch = !filters.type || row.type === filters.type.value;
        const storeMatch = !filters.store || row.storeName === filters.store.label;
        const locationMatch = !filters.location || row.location === filters.location.value;
        const categoryMatch = !filters.category || row.categoryName === filters.category.label;
        const productMatch = !filters.product || row.productName === filters.product.label;
        const merchandiserMatch = !filters.merchandiser || row.merchandiserName === filters.merchandiser.label;

        return (
          dateInRange &&
          typeMatch &&
          storeMatch &&
          locationMatch &&
          categoryMatch &&
          productMatch &&
          merchandiserMatch
        );
      } catch (error) {
        console.error('Error filtering row:', error);
        return false;
      }
    });
  };

  const filteredOpportunityData = getFilteredData();

  const styles = StyleSheet.create({
    page: { padding: 30, orientation: 'landscape' },
    title: { fontSize: 18, marginBottom: 20, textAlign: 'center' },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0
    },
    tableRow: { flexDirection: 'row' },
    tableColHeader: {
      width: '11%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      padding: 5,
      backgroundColor: '#f3f4f6',
      fontSize: 10
    },
    tableCol: {
      width: '11%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      padding: 5,
      fontSize: 9
    }
  });

  const MyDocument = () => (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <Text style={styles.title}>Opportunity/Threat Report</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            {['Date', 'Type', 'Store', 'Location', 'Category', 'Product', 'SKU', 'Description', 'Merchandiser'].map((header) => (
              <View key={header} style={styles.tableColHeader}>
                <Text>{header}</Text>
              </View>
            ))}
          </View>
          {filteredOpportunityData.map((row, i) => (
            <View key={i} style={styles.tableRow}>
              <View style={styles.tableCol}><Text>{row.date}</Text></View>
              <View style={styles.tableCol}><Text>{row.type}</Text></View>
              <View style={styles.tableCol}><Text>{row.storeName}</Text></View>
              <View style={styles.tableCol}><Text>{row.location}</Text></View>
              <View style={styles.tableCol}><Text>{row.categoryName}</Text></View>
              <View style={styles.tableCol}><Text>{row.productName}</Text></View>
              <View style={styles.tableCol}><Text>{row.productNumber}</Text></View>
              <View style={styles.tableCol}><Text>{row.description}</Text></View>
              <View style={styles.tableCol}><Text>{row.merchandiserName}</Text></View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );

  const handleExcelDownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredOpportunityData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Opportunities Report");
    XLSX.writeFile(workbook, "opportunities_report.xlsx");
  };

  const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => {
    try {
      const start = filters.dateRange[0] || defaultDateRange[0];
      const end = filters.dateRange[1] || defaultDateRange[1];
      const displayValue = `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`;
      return (
        <button 
          className="w-full px-4 py-2 text-left bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500" 
          onClick={onClick} 
          ref={ref}
        >
          {displayValue}
        </button>
      );
    } catch (error) {
      console.error('Error in CustomDateInput:', error);
      return null;
    }
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 bg-white rounded-lg shadow-lg">
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Opportunities & Threats</h1>
      </div>

      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          <div className="lg:col-span-4">
            <DatePicker
              selected={filters.dateRange[0] || defaultDateRange[0]}
              onChange={(dates) => {
                const [start, end] = dates;
                setFilters(prev => ({
                  ...prev,
                  dateRange: [
                    start || defaultDateRange[0],
                    end || defaultDateRange[1]
                  ]
                }));
              }}
              startDate={filters.dateRange[0] || defaultDateRange[0]}
              endDate={filters.dateRange[1] || defaultDateRange[1]}
              selectsRange
              customInput={<CustomDateInput />}
            />
          </div>

          <Select
            value={filters.type}
            onChange={(type) => setFilters(prev => ({ ...prev, type }))}
            options={types}
            isClearable
            placeholder="Select Type"
            className="react-select-container"
            classNamePrefix="react-select"
          />

          <Select
            value={filters.store}
            onChange={handleStoreChange}
            options={stores}
            isClearable
            placeholder="Select Store"
            className="react-select-container"
            classNamePrefix="react-select"
          />
          
          <Select
            value={filters.location}
            onChange={(location) => setFilters(prev => ({ ...prev, location }))}
            options={filteredLocations}
            isClearable
            placeholder="Select Location"
            isDisabled={!filters.store}
            className="react-select-container"
            classNamePrefix="react-select"
          />
          
          <Select
            value={filters.merchandiser}
            onChange={(merchandiser) => setFilters(prev => ({ ...prev, merchandiser }))}
            options={users}
            isClearable
            placeholder="Select Merchandiser"
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>

        <div className="bg-white rounded-lg shadow-md">
          <div className="flex justify-between items-center p-4 border-b">
            <h2 className="text-xl font-semibold text-gray-900">
              Opportunity/Threat Details ({filteredOpportunityData.length} records)
            </h2>
            <div className="space-x-2">
              <PDFDownloadLink document={<MyDocument />} fileName="opportunity_threat_report.pdf">
                {({ loading }) => (
                  <button className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition-colors">
                    {loading ? 'Loading PDF...' : 'Download PDF'}
                  </button>
                )}
              </PDFDownloadLink>
              <button 
                onClick={handleExcelDownload} 
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded transition-colors"
              >
                Download Excel
              </button>
              <CSVLink 
                data={filteredOpportunityData} 
                filename="opportunity_threat_report.csv"
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded inline-block transition-colors"
              >
                Download CSV
              </CSVLink>
            </div>
          </div>

          <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Store</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">SKU</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Photo</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Merchandiser</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredOpportunityData.length === 0 ? (
                  <tr>
                    <td colSpan="11" className="px-6 py-4 text-center text-gray-500">
                      No records found matching the selected filters
                    </td>
                  </tr>
                ) : (
                  filteredOpportunityData.map((row) => (
                    <tr key={row.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.date}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                          row.type === 'Opportunity' 
                            ? 'bg-green-100 text-green-800'
                            : 'bg-red-100 text-red-800'
                        }`}>
                          {row.type}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.storeName}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.location}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.categoryName}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.productName}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.productNumber}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {row.photoUrl && (
                          <img 
                            src={row.photoUrl} 
                            alt="Opportunity/Threat" 
                            className="w-16 h-16 object-cover rounded cursor-pointer hover:opacity-75 transition-opacity"
                            onClick={() => window.open(row.photoUrl, '_blank')}
                          />
                        )}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900 max-w-md">
                        <div className="line-clamp-2 hover:line-clamp-none">
                          {row.description}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {row.merchandiserName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        {isUploading ? (
                          <div className="flex items-center">
                            <div className="w-full bg-gray-200 rounded-full h-2.5">
                              <div className="bg-blue-600 h-2.5 rounded-full" style={{width: '100%'}} />
                            </div>
                          </div>
                        ) : (
                          <button
                            onClick={() => handleDelete(row.id)}
                            className="text-red-600 hover:text-red-900 px-3 py-1 rounded-md border border-red-600 hover:border-red-900 transition-colors"
                            disabled={isUploading}
                          >
                            Delete
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpportunityThreat;
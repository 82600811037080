import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebase';
import { ref, get } from 'firebase/database';
import './App.css';
import './index.css';
import "react-datepicker/dist/react-datepicker.css";

// Component imports
import Sidebar from './components/Sidebar';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import BusinessOverview from './components/BusinessOverview';
import MerchandiserTimeSheet from './components/MerchandiserTimeSheet';
import StockCountByStore from './components/StockCountByStore';
import OutOfStock from './components/OutOfStock';
import PriceAudit from './components/PriceAudit';
import PlanogramComplianceTracker from './components/PlanogramComplianceTracker';
import ProductExpiryDamageTracker from './components/ProductExpiryDamageTracker';
import MarketingActivity from './components/MarketingActivity';
import OpportunityThreat from './components/OpportunityThreat';
// import Notification from './components/Notification';
// import SendNotification from './components/SendNotification';
import RetailAnalytics from './components/RetailAnalytics';


function App() {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userRef = ref(db, `users/${user.uid}`);
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setUserRole(userData.role);
          }
        } catch (error) {
          console.error("Error fetching user role:", error);
        }
      }
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const ProtectedRoute = ({ children, allowedRoles = [] }) => {
    if (loading) return <div>Loading...</div>;
    
    if (!user) {
      return <Navigate to="/login" />;
    }

    // Always allow admin access, regardless of specified roles
    if (userRole === 'Admin' || allowedRoles.length === 0 || allowedRoles.includes(userRole)) {
      return (
        <div className="flex h-screen overflow-hidden">
          <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} userRole={userRole} />
          <div className="flex-1 flex flex-col overflow-hidden">
            <header className="bg-white shadow-sm lg:hidden">
              <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                <button onClick={toggleSidebar} className="text-gray-500 focus:outline-none focus:text-gray-600">
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
              </div>
            </header>
            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
              <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
                {children}
              </div>
            </main>
          </div>
        </div>
      );
    }

    return <Navigate to="/dashboard" />;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    
    <Router>
      <div className="App h-screen flex flex-col">
      
        <Routes>
          {/* Public routes */}
          <Route path="/login" element={user ? <Navigate to="/dashboard" /> : <Login />} />
          <Route path="/register" element={user ? <Navigate to="/dashboard" /> : <Register />} />

          {/* Protected routes - accessible to all authenticated users */}
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/business-overview" element={<ProtectedRoute><BusinessOverview /></ProtectedRoute>} />
          <Route path="/merchandiser-timesheet" element={<ProtectedRoute><MerchandiserTimeSheet /></ProtectedRoute>} />
          <Route path="/stock-count" element={<ProtectedRoute><StockCountByStore /></ProtectedRoute>} />
          <Route path="/out-of-stock" element={<ProtectedRoute><OutOfStock /></ProtectedRoute>} />
          <Route path="/price-audit" element={<ProtectedRoute><PriceAudit /></ProtectedRoute>} />
          <Route path="/planogram-compliance" element={<ProtectedRoute><PlanogramComplianceTracker /></ProtectedRoute>} />
          <Route path="/product-expiry-damage" element={<ProtectedRoute><ProductExpiryDamageTracker /></ProtectedRoute>} />
          <Route path="/marketing-activity" element={<ProtectedRoute><MarketingActivity /></ProtectedRoute>} />
          <Route path="/opportunity-threat" element={<ProtectedRoute><OpportunityThreat /></ProtectedRoute>} />
          <Route path="/retail-analytics" element={<ProtectedRoute><RetailAnalytics /></ProtectedRoute>} />
          {/* <Route path="/notifications" element={<ProtectedRoute><Notification /></ProtectedRoute>} /> */}
          
          {/* Role-restricted route */}
          {/* <Route path="/send-notification" element={<ProtectedRoute allowedRoles={['Admin', 'Manager']}><SendNotification /></ProtectedRoute>} /> */}

          {/* Default route */}
          <Route path="/" element={<Navigate to={user ? "/dashboard" : "/login"} />} />
          
          {/* Catch-all route */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

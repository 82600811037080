import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, remove, off } from 'firebase/database';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import * as XLSX from 'xlsx';
import PropTypes from 'prop-types';

// Utility functions
const formatNumber = (number, decimals = 0) => {
  if (number === null || number === undefined || isNaN(number)) {
    return '0';
  }
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(parseFloat(number));
};

const formatDateTime = (timestamp) => {
  if (!timestamp) return 'N/A';
  try {
    let date;
    if (timestamp.seconds) {
      date = new Date(timestamp.seconds * 1000);
    } else if (timestamp._seconds) {
      date = new Date(timestamp._seconds * 1000);
    } else if (typeof timestamp === 'number') {
      date = new Date(timestamp);
    } else {
      date = new Date(timestamp);
    }
    
    if (isNaN(date.getTime())) {
      console.error('Invalid date:', timestamp);
      return 'Invalid Date';
    }
    
    return format(date, 'MMM d, yyyy h:mma').replace(':00', '');
  } catch (error) {
    console.error('Error formatting date:', error, timestamp);
    return 'Date Error';
  }
};

const UNITS_PER_CASE = 24;

// Calculate values based on unit type
const getValueInUnits = (value, unitType) => {
  const numericValue = parseFloat(value) || 0;
  return unitType === 'Cases' ? numericValue * UNITS_PER_CASE : numericValue;
};

const getValueInCases = (value, unitType) => {
  const numericValue = parseFloat(value) || 0;
  return unitType === 'Units' ? numericValue / UNITS_PER_CASE : numericValue;
};

const StockCountByStore = ({ className = '' }) => {
  // State Management
  const [stockCounts, setStockCounts] = useState([]);
  const [stores, setStores] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Filter States
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedMerchandiser, setSelectedMerchandiser] = useState(null);
  const [filteredLocations, setFilteredLocations] = useState([]);

  // Custom DatePicker Input
  const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <button
      className="w-full px-4 py-2 text-left bg-white border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
      onClick={onClick}
      ref={ref}
    >
      {value || "Select Date Range"}
    </button>
  ));

  CustomDateInput.displayName = 'CustomDateInput';

  // Helper Functions
  const calculateTotalStocks = (item) => {
    if (!item) return { units: 0, cases: 0 };

    // Calculate shelf stock
    const shelfUnits = getValueInUnits(item.stockOnShelf, item.stockOnShelfUnit);
    const shelfCases = getValueInCases(item.stockOnShelf, item.stockOnShelfUnit);

    // Calculate packed stock
    const packedUnits = getValueInUnits(item.stocksPacked, item.stocksPackedUnit);
    const packedCases = getValueInCases(item.stocksPacked, item.stocksPackedUnit);

    // Calculate store stock
    const storeUnits = getValueInUnits(item.stocksInStore, item.stocksInStoreUnit);
    const storeCases = getValueInCases(item.stocksInStore, item.stocksInStoreUnit);

    return {
      units: shelfUnits + packedUnits + storeUnits,
      cases: shelfCases + packedCases + storeCases
    };
  };

  const getStoreInfo = (storeId) => {
    if (!storeId) return { name: 'Unknown Store', location: 'Unknown Location' };
    
    const store = stores.find(store => store.id === storeId);
    if (!store) {
      console.warn(`Store not found for ID: ${storeId}`);
      return { name: 'Store Not Found', location: 'Location Unknown' };
    }
    
    return {
      name: store.storeName || 'Unnamed Store',
      location: store.searchLocation || 'No Location'
    };
  };

  const getUserInfo = (userId) => {
    if (!userId) return { name: 'No User ID' };
    
    const user = users.find(user => user.id === userId);
    if (!user) {
      console.warn(`User not found for ID: ${userId}`);
      return { name: `User ID: ${userId}` };
    }
    
    return {
      name: user.fullName || user.name || 'Unnamed User'
    };
  };

  const getCategoryName = (categoryId) => {
    if (!categoryId) return 'Unknown Category';
    const category = categories.find(category => category.id === categoryId);
    return category ? category.categoryName : 'Category Not Found';
  };

  const getProductName = (productId) => {
    if (!productId) return 'Unknown Product';
    const product = products.find(product => product.id === productId);
    return product ? product.productName : 'Product Not Found';
  };

  // Fetch Data from Firebase
  useEffect(() => {
    const db = getDatabase();
    setLoading(true);
    setError(null);

    const fetchData = async () => {
      try {
        // Fetch Stock Counts
        const stockCountsRef = ref(db, 'stock');
        onValue(stockCountsRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = Object.entries(snapshot.val()).map(([id, data]) => ({
              id,
              ...data,
              timestamp: data.timestamp ? 
                (data.timestamp.seconds ? 
                  new Date(data.timestamp.seconds * 1000) : 
                  new Date(data.timestamp)
                ) : new Date()
            }));
            setStockCounts(data);
          } else {
            setStockCounts([]);
          }
        });

        // Fetch Stores
        const storesRef = ref(db, 'stores');
        onValue(storesRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = Object.entries(snapshot.val()).map(([id, data]) => ({
              id,
              ...data
            }));
            setStores(data);
          } else {
            setStores([]);
          }
        });

        // Fetch Categories
        const categoriesRef = ref(db, 'categories');
        onValue(categoriesRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = Object.entries(snapshot.val()).map(([id, data]) => ({
              id,
              ...data
            }));
            setCategories(data);
          } else {
            setCategories([]);
          }
        });

        // Fetch Products
        const productsRef = ref(db, 'products');
        onValue(productsRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = Object.entries(snapshot.val()).map(([id, data]) => ({
              id,
              ...data
            }));
            setProducts(data);
          } else {
            setProducts([]);
          }
        });

        // Fetch Users
        const usersRef = ref(db, 'users');
        onValue(usersRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = Object.entries(snapshot.val()).map(([id, data]) => ({
              id,
              ...data
            }));
            setUsers(data);
          } else {
            setUsers([]);
          }
        });

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      off(ref(db, 'stock'));
      off(ref(db, 'stores'));
      off(ref(db, 'categories'));
      off(ref(db, 'products'));
      off(ref(db, 'users'));
    };
  }, []);

  // Update filtered locations when store is selected
  useEffect(() => {
    if (!selectedStore) {
      setFilteredLocations([...new Set(stores.map(store => store.searchLocation))]);
      setSelectedLocation(null);
    } else {
      const storeLocations = stores
        .filter(store => store.id === selectedStore.value)
        .map(store => store.searchLocation);
      setFilteredLocations(storeLocations);
      setSelectedLocation(null);
    }
  }, [selectedStore, stores]);

  // Filter Data
  const filteredData = stockCounts.filter(item => {
    const matchesDate = !startDate || !endDate || 
      (item.timestamp >= startDate && item.timestamp <= endDate);
    const matchesStore = !selectedStore || item.storeId === selectedStore.value;
    const matchesLocation = !selectedLocation || 
      getStoreInfo(item.storeId).location === selectedLocation.value;
    const matchesCategory = !selectedCategory || item.categoryId === selectedCategory.value;
    const matchesProduct = !selectedProduct || item.productId === selectedProduct.value;
    const matchesMerchandiser = !selectedMerchandiser || 
      item.uploadedBy === selectedMerchandiser.value;

    return matchesDate && matchesStore && matchesLocation && 
           matchesCategory && matchesProduct && matchesMerchandiser;
  }).sort((a, b) => b.timestamp - a.timestamp);

  // Handle Delete
  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this stock count?')) {
      const db = getDatabase();
      try {
        await remove(ref(db, `stock/${id}`));
      } catch (error) {
        console.error('Error deleting stock count:', error);
        alert('Failed to delete stock count. Please try again.');
      }
    }
  };

  // Chart Data Preparation
  const prepareChartData = () => {
    return products.map(product => {
      const productStocks = filteredData.filter(item => item.productId === product.id);
      const totalUnits = productStocks.reduce((acc, item) => {
        const stocks = calculateTotalStocks(item);
        return acc + stocks.units;
      }, 0);
      
      const totalCases = productStocks.reduce((acc, item) => {
        const stocks = calculateTotalStocks(item);
        return acc + stocks.cases;
      }, 0);

      return {
        name: product.productName,
        Units: totalUnits,
        Cases: totalCases
      };
    }).filter(item => item.Units > 0 || item.Cases > 0);
  };

  const calculateSummaryMetrics = (filteredData) => {
    const totalStocks = filteredData.reduce((acc, item) => {
      const stocks = calculateTotalStocks(item);
      return {
        cases: acc.cases + (stocks.cases || 0),
        units: acc.units + (stocks.units || 0)
      };
    }, { cases: 0, units: 0 });

    const sortedData = [...filteredData].sort((a, b) => 
      new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );
    
    const openingStock = sortedData[0] ? calculateTotalStocks(sortedData[0]) : 
      { cases: 0, units: 0 };
    const closingStock = sortedData[sortedData.length - 1] ? 
      calculateTotalStocks(sortedData[sortedData.length - 1]) : 
      { cases: 0, units: 0 };

    return {
      totalStock: totalStocks,
      openingStock: openingStock,
      closingStock: closingStock,
      averageStock: {
        cases: totalStocks.cases / (filteredData.length || 1),
        units: totalStocks.units / (filteredData.length || 1)
      }
    };
  };

  const renderSummaryCards = (metrics) => (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
      <div className="bg-white p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-2">Total Stock Count</h3>
        <p className="text-2xl font-bold">{formatNumber(metrics.totalStock.cases)} Cases</p>
        <p className="text-gray-600">{formatNumber(metrics.totalStock.units)} Units</p>
      </div>

      <div className="bg-white p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-2">Opening Stock</h3>
        <p className="text-2xl font-bold">{formatNumber(metrics.openingStock.cases)} Cases</p>
        <p className="text-gray-600">{formatNumber(metrics.openingStock.units)} Units</p>
      </div>

      <div className="bg-white p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-2">Closing Stock</h3>
        <p className="text-2xl font-bold">{formatNumber(metrics.closingStock.cases)} Cases</p>
        <p className="text-gray-600">{formatNumber(metrics.closingStock.units)} Units</p>
      </div>

      <div className="bg-white p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-2">Average Stock</h3>
        <p className="text-2xl font-bold">{formatNumber(metrics.averageStock.cases, 2)} Cases</p>
        <p className="text-gray-600">{formatNumber(metrics.averageStock.units, 2)} Units</p>
      </div>
    </div>
  );

  const renderChart = (chartData) => (
    <div className="bg-white p-4 rounded-lg shadow mb-6">
      <h2 className="text-xl font-bold mb-4">Stock by Product</h2>
      <div className="h-80">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="name" 
              angle={-45} 
              textAnchor="end" 
              height={80}
              interval={0}
              tick={{fontSize: 12}}
            />
            <YAxis 
              yAxisId="units"
              orientation="left"
              label={{ 
                value: 'Units', 
                angle: -90, 
                position: 'insideLeft',
                style: { textAnchor: 'middle' }
              }}
            />
            <YAxis 
              yAxisId="cases"
              orientation="right"
              label={{ 
                value: 'Cases', 
                angle: 90, 
                position: 'insideRight',
                style: { textAnchor: 'middle' }
              }}
            />
            <Tooltip formatter={(value) => formatNumber(value)} />
            <Legend />
            <Bar 
              dataKey="Units" 
              fill="#4f46e5" 
              yAxisId="units"
              radius={[4, 4, 0, 0]}
              name="Total Units"
            />
            <Bar 
              dataKey="Cases" 
              fill="#10b981" 
              yAxisId="cases"
              radius={[4, 4, 0, 0]}
              name="Total Cases"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  const renderFilters = () => (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
      <div className="col-span-full md:col-span-1">
        <DatePicker
          selectsRange
          startDate={startDate}
          endDate={endDate}
          onChange={setDateRange}
          customInput={<CustomDateInput />}
          placeholderText="Select Date Range"
          className="w-full"
        />
      </div>

      <Select
        value={selectedStore}
        onChange={setSelectedStore}
        options={stores.map(store => ({
          value: store.id,
          label: store.storeName
        }))}
        placeholder="Select Store"
        isClearable
        className="react-select-container"
      />

      <Select
        value={selectedLocation}
        onChange={setSelectedLocation}
        options={filteredLocations.map(location => ({
          value: location,
          label: location
        }))}
        placeholder="Select Location"
        isClearable
        isDisabled={!selectedStore}
        className="react-select-container"
      />

      <Select
        value={selectedCategory}
        onChange={setSelectedCategory}
        options={categories.map(category => ({
          value: category.id,
          label: category.categoryName
        }))}
        placeholder="Select Category"
        isClearable
        className="react-select-container"
      />

      <Select
        value={selectedProduct}
        onChange={setSelectedProduct}
        options={products.map(product => ({
          value: product.id,
          label: product.productName
        }))}
        placeholder="Select Product"
        isClearable
        className="react-select-container"
      />

      <Select
        value={selectedMerchandiser}
        onChange={setSelectedMerchandiser}
        options={users.map(user => ({
          value: user.id,
          label: user.fullName
        }))}
        placeholder="Select Merchandiser"
        isClearable
        className="react-select-container"
      />
    </div>
  );

  const renderTableBody = () => (
    <tbody className="bg-white divide-y divide-gray-200">
      {filteredData.map(item => {
        const totalStocks = calculateTotalStocks(item);
        const storeInfo = getStoreInfo(item.storeId);
        const userInfo = getUserInfo(item.uploadedBy);

        return (
          <tr key={item.id} className="hover:bg-gray-50">
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {formatDateTime(item.timestamp)}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {storeInfo.name}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {storeInfo.location}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {getCategoryName(item.categoryId)}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {getProductName(item.productId)}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {item.sku || 'N/A'}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {formatNumber(item.stockOnShelf)}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {item.stockOnShelfUnit}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {formatNumber(item.stocksPacked)}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {formatNumber(item.stocksInStore)}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {item.stocksInStoreUnit}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {formatNumber(totalStocks.units)}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {formatNumber(totalStocks.cases)}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {userInfo.name}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              <button
                onClick={() => handleDelete(item.id)}
                className="text-red-600 hover:text-red-900 font-medium"
              >
                Delete
              </button>
            </td>
          </tr>
        );
      })}
      {filteredData.length === 0 && (
        <tr>
          <td colSpan="15" className="px-6 py-4 text-center text-gray-500">
            No stock count records found
          </td>
        </tr>
      )}
    </tbody>
  );

  // Export Functions
  const exportToExcel = () => {
    const exportData = filteredData.map(item => {
      const storeInfo = getStoreInfo(item.storeId);
      const userInfo = getUserInfo(item.uploadedBy);
      const totalStocks = calculateTotalStocks(item);

      return {
        'Date & Time': formatDateTime(item.timestamp),
        'Store': storeInfo.name,
        'Location': storeInfo.location,
        'Category': getCategoryName(item.categoryId),
        'Product Name': getProductName(item.productId),
        'SKU': item.sku || 'N/A',
        'Stock on Shelf': formatNumber(item.stockOnShelf),
        'Shelf Unit': item.stockOnShelfUnit,
        'Stock Packed': formatNumber(item.stocksPacked),
        'Stock in Store': formatNumber(item.stocksInStore),
        'Store Unit': item.stocksInStoreUnit,
        'Total Units': formatNumber(totalStocks.units),
        'Total Cases': formatNumber(totalStocks.cases),
        'Merchandiser': userInfo.name
      };
    });

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Stock Count Data');

    // Add summary sheet
    const metrics = calculateSummaryMetrics(filteredData);
    const summaryData = [
      ['Stock Count Summary'],
      ['Generated Date:', formatDateTime(new Date())],
      ['Period:', startDate && endDate ? 
        `${formatDateTime(startDate)} - ${formatDateTime(endDate)}` : 'All Time'],
      [''],
      ['Metrics', 'Cases', 'Units'],
      ['Total Stock', formatNumber(metrics.totalStock.cases), formatNumber(metrics.totalStock.units)],
      ['Opening Stock', formatNumber(metrics.openingStock.cases), formatNumber(metrics.openingStock.units)],
      ['Closing Stock', formatNumber(metrics.closingStock.cases), formatNumber(metrics.closingStock.units)],
      ['Average Stock', formatNumber(metrics.averageStock.cases, 2), formatNumber(metrics.averageStock.units, 2)]
    ];

    const summaryWs = XLSX.utils.aoa_to_sheet(summaryData);
    XLSX.utils.book_append_sheet(wb, summaryWs, 'Summary');

    XLSX.writeFile(wb, 'stock_count_report.xlsx');
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  const metrics = calculateSummaryMetrics(filteredData);
  const chartData = prepareChartData();

  return (
    <div className={`container mx-auto px-4 py-8 ${className}`}>
      <div className="bg-white rounded-lg shadow">
        <div className="p-6">
          <h1 className="text-2xl font-bold mb-6">Stock Count by Store</h1>
          
          {renderFilters()}
          {renderSummaryCards(metrics)}
          {renderChart(chartData)}

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date & Time</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Store</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">SKU</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Stock on Shelf</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Shelf Unit</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Stock Packed</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Stock in Store</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Store Unit</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Units</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Cases</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Merchandiser</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              {renderTableBody()}
            </table>
          </div>

          <div className="flex gap-4 mt-6">
            <button
              onClick={exportToExcel}
              className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded transition-colors duration-200"
            >
              Export to Excel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

StockCountByStore.propTypes = {
  className: PropTypes.string
};

export default StockCountByStore;

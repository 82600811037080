import React, { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { db } from '../firebase';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Building, Store, Fuel, Pill, GlassWater } from 'lucide-react';
import MapComponent from './MapComponent';

import "react-datepicker/dist/react-datepicker.css";

// Constants
const UNITS_PER_CASE = 24;

// Utility functions
const formatNumber = (number, decimals = 0) => {
  if (number === null || number === undefined || isNaN(number)) {
    return '0';
  }
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(parseFloat(number));
};

const getValueInUnits = (value, unitType) => {
  const numericValue = parseFloat(value) || 0;
  return unitType === 'Cases' ? numericValue * UNITS_PER_CASE : numericValue;
};

const getValueInCases = (value, unitType) => {
  const numericValue = parseFloat(value) || 0;
  return unitType === 'Units' ? numericValue / UNITS_PER_CASE : numericValue;
};

const calculateTotalStocks = (item) => {
  if (!item) return { units: 0, cases: 0 };

  // Calculate shelf stock
  const shelfUnits = getValueInUnits(item.stockOnShelf, item.stockOnShelfUnit);
  const shelfCases = getValueInCases(item.stockOnShelf, item.stockOnShelfUnit);

  // Calculate packed stock
  const packedUnits = getValueInUnits(item.stocksPacked, item.stocksPackedUnit);
  const packedCases = getValueInCases(item.stocksPacked, item.stocksPackedUnit);

  // Calculate store stock
  const storeUnits = getValueInUnits(item.stocksInStore, item.stocksInStoreUnit);
  const storeCases = getValueInCases(item.stocksInStore, item.stocksInStoreUnit);

  return {
    units: shelfUnits + packedUnits + storeUnits,
    cases: shelfCases + packedCases + storeCases
  };
};

const TotalStockCard = ({ loading, error, metrics }) => {
  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-md">
        <div className="p-4 border-b">
          <h3 className="text-lg font-semibold text-blue-500">Total Stock Count</h3>
        </div>
        <div className="p-4">
          <div className="flex items-center justify-center h-20">
            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500" />
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-lg shadow-md">
        <div className="p-4 border-b">
          <h3 className="text-lg font-semibold text-blue-500">Total Stock Count</h3>
        </div>
        <div className="p-4">
          <div className="text-red-500">Error loading stock data</div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md">
      <div className="p-4 border-b">
        <h3 className="text-lg font-semibold text-blue-500">Total Stock Count</h3>
      </div>
      <div className="p-4">
        <div className="text-3xl font-bold text-green-600 mb-2">
          {formatNumber(metrics.totalStockCases)} Cases
        </div>
        <div className="text-3xl font-bold text-green-600">
          {formatNumber(metrics.totalStockUnits)} Units
        </div>
      </div>
    </div>
  );
};

const BusinessOverview = () => {
  // State management
  const defaultDateRange = [new Date(), new Date()];
  const [filters, setFilters] = useState({
    dateRange: defaultDateRange,
    category: null,
    store: null,
    product: null,
    company: null
  });

  const [stockData, setStockData] = useState([]);
  const [stores, setStores] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [companies] = useState([]);
  const [timesheetData, setTimesheetData] = useState([]);
  const [expiryData, setExpiryData] = useState([]);
  const [outOfStockData, setOutOfStockData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Channel colors for the map
  const channelColors = {
    Supermarket: '#4CAF50',
    Wholesale: '#2196F3',
    'Gas Station': '#FFC107',
    Pharmacy: '#9C27B0',
    Bar: '#9C27B0'
  };

  const calculateServicedStores = (timesheetData, stores) => {
    const uniqueServicedStores = new Set(timesheetData
      .filter(timesheet => timesheet.status === "completed")
      .map(timesheet => timesheet.storeId)
    ).size;
    return uniqueServicedStores;
  };

  const calculateOutOfStockStores = (outOfStockData) => {
    return new Set(outOfStockData.map(item => item.storeId)).size;
  };

  const calculateExpiredProductStores = (expiryData) => {
    return new Set(
      expiryData
        .filter(item => item.status === 'Expired')
        .map(item => item.storeId)
    ).size;
  };

  const getFilteredStores = () => {
    let filteredStores = [...stores];
    if (filters.company) {
      filteredStores = filteredStores.filter(store => 
        store.companyId === filters.company.value
      );
    }
    return filteredStores;
  };

  // Load data
  useEffect(() => {
    const unsubscribers = [];

    const loadData = async () => {
      try {
        setLoading(true);
        
        // Load stock data
        const stockRef = ref(db, 'stock');
        const stockUnsubscribe = onValue(stockRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = Object.values(snapshot.val()).map(item => ({
              ...item,
              timestamp: new Date(item.timestamp)
            }));
            setStockData(data);
          }
        });
        unsubscribers.push(stockUnsubscribe);

        // Load timesheet data
        const timesheetRef = ref(db, 'timesheets');
        const timesheetUnsubscribe = onValue(timesheetRef, (snapshot) => {
          if (snapshot.exists()) {
            setTimesheetData(Object.values(snapshot.val()));
          }
        });
        unsubscribers.push(timesheetUnsubscribe);

        // Load out of stock data
        const outOfStockRef = ref(db, 'outOfStock');
        const outOfStockUnsubscribe = onValue(outOfStockRef, (snapshot) => {
          if (snapshot.exists()) {
            setOutOfStockData(Object.values(snapshot.val()));
          }
        });
        unsubscribers.push(outOfStockUnsubscribe);

        // Load expiry data
        const expiryRef = ref(db, 'productExpiry');
        const expiryUnsubscribe = onValue(expiryRef, (snapshot) => {
          if (snapshot.exists()) {
            setExpiryData(Object.values(snapshot.val()));
          }
        });
        unsubscribers.push(expiryUnsubscribe);

        // Load stores data
        const storesRef = ref(db, 'stores');
        const storesUnsubscribe = onValue(storesRef, (snapshot) => {
          if (snapshot.exists()) {
            const storesList = Object.entries(snapshot.val()).map(([id, store]) => ({
              id,
              ...store,
              value: id,
              label: store.storeName
            }));
            setStores(storesList);
          }
        });
        unsubscribers.push(storesUnsubscribe);

        // Load categories
        const categoriesRef = ref(db, 'categories');
        const categoriesUnsubscribe = onValue(categoriesRef, (snapshot) => {
          if (snapshot.exists()) {
            const categoriesList = Object.entries(snapshot.val()).map(([id, category]) => ({
              value: id,
              label: category.categoryName
            }));
            setCategories(categoriesList);
          }
        });
        unsubscribers.push(categoriesUnsubscribe);

        // Load products
        const productsRef = ref(db, 'products');
        const productsUnsubscribe = onValue(productsRef, (snapshot) => {
          if (snapshot.exists()) {
            const productsList = Object.entries(snapshot.val()).map(([id, product]) => ({
              value: id,
              label: product.productName,
              categoryId: product.categoryId
            }));
            setProducts(productsList);
          }
        });
        unsubscribers.push(productsUnsubscribe);

        setLoading(false);
      } catch (error) {
        console.error('Error loading data:', error);
        setLoading(false);
      }
    };

    loadData();

    return () => {
      unsubscribers.forEach(unsubscribe => unsubscribe());
    };
  }, []);

  // Calculate metrics
  const calculateMetrics = () => {
    const startDate = filters.dateRange[0] || defaultDateRange[0];
    const endDate = filters.dateRange[1] || defaultDateRange[1];

    // Filter stock data based on date range and other filters
    let filteredStockData = stockData.filter(item => {
      const matchesDate = item.timestamp >= startDate && item.timestamp <= endDate;
      const matchesStore = !filters.store || item.storeId === filters.store.value;
      const matchesCategory = !filters.category || item.categoryId === filters.category.value;
      const matchesProduct = !filters.product || item.productId === filters.product.value;
      
      return matchesDate && matchesStore && matchesCategory && matchesProduct;
    });

    // Calculate total stocks for filtered data
    const totalStocks = filteredStockData.reduce((acc, item) => {
      const stocks = calculateTotalStocks(item);
      return {
        cases: acc.cases + (stocks.cases || 0),
        units: acc.units + (stocks.units || 0)
      };
    }, { cases: 0, units: 0 });

    const servicedStores = calculateServicedStores(timesheetData, stores);
    const outOfStockStores = calculateOutOfStockStores(outOfStockData);
    const expiredProductStores = calculateExpiredProductStores(expiryData);

    // Calculate stores by channel
    let filteredStores = getFilteredStores();
    const storesByChannel = filteredStores.reduce((acc, store) => {
      acc[store.channel] = (acc[store.channel] || 0) + 1;
      return acc;
    }, {
      Supermarket: 0,
      Wholesale: 0,
      'Gas Station': 0,
      Pharmacy: 0,
      Bar: 0,
    });
 
    return {
      totalStockCases: totalStocks.cases,
      totalStockUnits: totalStocks.units,
      servicedStores,
      outOfStockStores,
      expiredProductStores,
      storesByChannel
    };
  };

  // Chart data preparation
  const getChartData = () => {
    const filteredStockData = stockData.filter(item => 
      !filters.category || 
      item.categoryId === filters.category.value
    );

    return products
      .filter(product => 
        !filters.category || 
        product.categoryId === filters.category.value
      )
      .map(product => {
        const productStock = filteredStockData.filter(stock => 
          stock.productId === product.value
        );
        
        const totalStocks = productStock.reduce((acc, item) => {
          const stocks = calculateTotalStocks(item);
          return {
            units: acc.units + stocks.units,
            cases: acc.cases + stocks.cases
          };
        }, { units: 0, cases: 0 });

        return {
          name: product.label,
          units: totalStocks.units,
          cases: totalStocks.cases
        };
      })
      .filter(item => item.units > 0 || item.cases > 0);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const metrics = calculateMetrics();

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Filters */}
      <div className="bg-white rounded-lg shadow-md mb-8">
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
            <div className="lg:col-span-5">
              <DatePicker
                selectsRange={true}
                startDate={filters.dateRange[0]}
                endDate={filters.dateRange[1]}
                onChange={(dates) => setFilters(prev => ({ ...prev, dateRange: dates }))}
                className="w-full p-2 border rounded"
                placeholderText="Select Date Range"
              />
            </div>

            <Select
              value={filters.company}
              onChange={(company) => setFilters(prev => ({ ...prev, company }))}
              options={companies}
              isClearable
              placeholder="Select Company"
            />

            <Select
              value={filters.category}
              onChange={(category) => setFilters(prev => ({ ...prev, category }))}
              options={categories}
              isClearable
              placeholder="Select Category"
            />
            
            <Select
              value={filters.store}
              onChange={(store) => setFilters(prev => ({ ...prev, store }))}
              options={stores}
              isClearable
              placeholder="Select Store"
            />
            
            <Select
              value={filters.product}
              onChange={(product) => setFilters(prev => ({ ...prev, product }))}
              options={products}
              isClearable
              placeholder="Select Product"
            />
          </div>
        </div>
      </div>

      {/* Metrics Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <TotalStockCard loading={loading} error={null} metrics={metrics} />

        <div className="bg-white rounded-lg shadow-md">
          <div className="p-4 border-b">
            <h3 className="text-lg font-semibold text-blue-500">Stores Serviced</h3>
          </div>
          <div className="p-4">
            <div className="text-3xl font-bold text-green-600">
              {metrics.servicedStores.toLocaleString()}
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md">
          <div className="p-4 border-b">
            <h3 className="text-lg font-semibold text-blue-500">Stores with Out of Stock</h3>
          </div>
          <div className="p-4">
            <div className="text-3xl font-bold text-green-600">
              {metrics.outOfStockStores.toLocaleString()}
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md">
          <div className="p-4 border-b">
            <h3 className="text-lg font-semibold text-blue-500">Stores with Expired Products</h3>
          </div>
          <div className="p-4">
            <div className="text-3xl font-bold text-green-600">
              {metrics.expiredProductStores.toLocaleString()}
            </div>
          </div>
        </div>
      </div>

      {/* Channel Distribution Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mb-8">
        <div className="bg-white rounded-lg shadow-md">
          <div className="p-4 border-b flex items-center space-x-2">
            <Building className="text-blue-500" />
            <h3 className="text-lg font-semibold text-blue-500">Supermarket</h3>
          </div>
          <div className="p-4">
            <div className="text-3xl font-bold text-green-600">
              {metrics.storesByChannel.Supermarket.toLocaleString()}
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md">
          <div className="p-4 border-b flex items-center space-x-2">
            <Store className="text-blue-500" />
            <h3 className="text-lg font-semibold text-blue-500">Wholesale</h3>
          </div>
          <div className="p-4">
            <div className="text-3xl font-bold text-green-600">
              {metrics.storesByChannel.Wholesale.toLocaleString()}
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md">
          <div className="p-4 border-b flex items-center space-x-2">
            <Fuel className="text-blue-500" />
            <h3 className="text-lg font-semibold text-blue-500">Gas Station</h3>
          </div>
          <div className="p-4">
            <div className="text-3xl font-bold text-green-600">
              {metrics.storesByChannel['Gas Station'].toLocaleString()}
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md">
          <div className="p-4 border-b flex items-center space-x-2">
            <Pill className="text-blue-500" />
            <h3 className="text-lg font-semibold text-blue-500">Pharmacy</h3>
          </div>
          <div className="p-4">
            <div className="text-3xl font-bold text-green-600">
              {metrics.storesByChannel.Pharmacy.toLocaleString()}
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md">
          <div className="p-4 border-b flex items-center space-x-2">
            <GlassWater className="text-blue-500" />
            <h3 className="text-lg font-semibold text-blue-500">Bar</h3>
          </div>
          <div className="p-4">
            <div className="text-3xl font-bold text-green-600">
              {metrics.storesByChannel.Bar.toLocaleString()}
            </div>
          </div>
        </div>
      </div>

      {/* Stock Chart */}
      <div className="bg-white rounded-lg shadow-md mb-8">
        <div className="p-4 border-b">
          <h3 className="text-lg font-semibold text-gray-900">Product Stock Distribution</h3>
        </div>
        <div className="p-4">
          <div className="h-96">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={getChartData()}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="name" 
                  angle={-45} 
                  textAnchor="end" 
                  height={80} 
                  interval={0}
                  tick={{ fontSize: 12 }}
                />
                <YAxis 
                  yAxisId="left" 
                  orientation="left"
                  label={{ 
                    value: 'Units', 
                    angle: -90, 
                    position: 'insideLeft',
                    style: { textAnchor: 'middle' }
                  }}
                />
                <YAxis 
                  yAxisId="right" 
                  orientation="right"
                  label={{ 
                    value: 'Cases', 
                    angle: 90, 
                    position: 'insideRight',
                    style: { textAnchor: 'middle' }
                  }}
                />
                <Tooltip formatter={(value) => Math.round(value).toLocaleString()} />
                <Legend />
                <Bar 
                  dataKey="units" 
                  fill="#2196F3" 
                  name="Units" 
                  yAxisId="left"
                  radius={[4, 4, 0, 0]}
                />
                <Bar 
                  dataKey="cases" 
                  fill="#4CAF50" 
                  name="Cases" 
                  yAxisId="right"
                  radius={[4, 4, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Maps Section */}
      <div className="bg-white rounded-lg shadow-md mb-8">
        <div className="p-4 border-b">
          <h3 className="text-lg font-semibold text-gray-900">Store Locations</h3>
        </div>
        <div className="p-4">
          <MapComponent 
            stores={getFilteredStores()} 
            channelColors={channelColors}
          />
        </div>
      </div>

      {/* Map Legend */}
      <div className="bg-white rounded-lg shadow-md mb-8">
        <div className="p-4">
          <h3 className="text-lg font-semibold text-gray-900 mb-4">Channel Types</h3>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
            {Object.entries(channelColors).map(([channel, color]) => (
              <div key={channel} className="flex items-center">
                <div 
                  className="w-4 h-4 rounded-full mr-2"
                  style={{ backgroundColor: color }}
                />
                <span className="text-sm text-gray-600">{channel}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessOverview;
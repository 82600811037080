import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Download, FileText, Info } from 'lucide-react';
import { getDatabase, ref, onValue, remove, off } from 'firebase/database';
import { format, isValid } from 'date-fns';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import ProductPriceCards from './ProductPriceCards';

// PriceIndexCard Component
const PriceIndexCard = ({ priceData }) => {
  const calculatePriceIndex = React.useMemo(() => {
    if (!priceData || priceData.length === 0) return 0;
    
    const maxStorePrice = Math.max(...priceData.map(item => item["Store Price"] || 0));
    const maxCompPrice = Math.max(...priceData.map(item => item["Competitor Price"] || 0));
    
    if (maxCompPrice === 0) return 0;
    return ((maxStorePrice / maxCompPrice) * 100) - 100;
  }, [priceData]);

  const getComparisonText = () => {
    if (calculatePriceIndex === 0) return "Equal Than Competitor Average Price";
    if (calculatePriceIndex > 0) return "Greater Than Competitor Average Price";
    return "Less Than Competitor Average Price";
  };

  const getTextColor = () => {
    if (calculatePriceIndex === 0) return "text-gray-600";
    if (calculatePriceIndex > 0) return "text-blue-500";
    return "text-green-500";
  };

  return (
    <div className="bg-white rounded-lg shadow mb-6 p-6">
      <div className="pb-2">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-semibold">Price Comparison Index</h3>
          <div className="group relative">
            <Info className="h-4 w-4 text-gray-400 cursor-help" />
            <div className="hidden group-hover:block absolute z-10 w-72 p-2 text-sm bg-gray-800 text-white rounded shadow-lg -right-2 top-6">
              Price Comparison Index = (Maximum Store Company Price/Maximum Competitor Price *100) - 100
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="text-4xl font-bold mb-2">
          {calculatePriceIndex.toFixed(1)}%
        </div>
        <div className={`text-sm ${getTextColor()}`}>
          {getComparisonText()}
        </div>
      </div>
    </div>
  );
};

// Utility functions
const isValidYear = (year) => {
  return year >= 2000 && year <= 2100;
};

const formatDateTime = (timestamp) => {
  if (!timestamp) return 'N/A';
  try {
    // Handle different timestamp formats
    let date;
    if (timestamp.seconds) {
      // Firebase Timestamp
      date = new Date(timestamp.seconds * 1000);
    } else if (timestamp._seconds) {
      // Another Firebase Timestamp format
      date = new Date(timestamp._seconds * 1000);
    } else if (typeof timestamp === 'number') {
      // Unix timestamp in milliseconds
      date = new Date(timestamp);
    } else if (typeof timestamp === 'string') {
      // If it's already in MM/DD/YYYY format, return as is
      if (timestamp.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
        return timestamp;
      }
      // If it's an ISO string or other date string, parse it
      date = new Date(timestamp);
    } else {
      // JavaScript Date object
      date = new Date(timestamp);
    }
    
    // Validate the date
    if (!isValid(date) || !isValidYear(date.getFullYear())) {
      console.error('Invalid date or year:', timestamp);
      return 'Invalid Date';
    }
    
    return format(date, 'MM/dd/yyyy');
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Date Error';
  }
};

const PriceAudit = () => {
  // State Management
  const [priceAudits, setPriceAudits] = useState([]);
  const [stores, setStores] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Filter States
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedMerchandiser, setSelectedMerchandiser] = useState(null);
  const [filteredLocations, setFilteredLocations] = useState([]);

  // Price Statistics
  const [priceStats, setPriceStats] = useState({
    maxPrice: 0,
    minPrice: 0,
    avgPrice: 0,
    competitorAvgPrice: 0,
    maxCompetitorPrice: 0
  });

  // Helper Functions
  const getProductDetails = (productId) => {
    const product = products.find(p => p.id === productId);
    return {
      productName: product?.productName || 'Unknown Product',
      productNumber: product?.productNumber || 'N/A',
      competitorProductName: product?.competitorProductName || 'No Competitor Product'
    };
  };

  const getStoreInfo = (storeId) => {
    if (!storeId) return { name: 'Unknown Store', location: 'Unknown Location' };
    
    const store = stores.find(s => s.id === storeId);
    if (!store) {
      console.warn(`Store not found for ID: ${storeId}`);
      return { name: 'Store Not Found', location: 'Location Unknown' };
    }
    
    return {
      name: store.storeName || 'Unnamed Store',
      location: store.searchLocation || 'No Location'
    };
  };

  const getCategoryName = (categoryId) => {
    if (!categoryId) return 'Unknown Category';
    const category = categories.find(c => c.id === categoryId);
    return category ? category.categoryName : 'Category Not Found';
  };

  const getUserInfo = (userId) => {
    if (!userId) return { name: 'No User ID' };
    
    const user = users.find(user => user.id === userId);
    if (!user) {
      console.warn(`User not found for ID: ${userId}`);
      return { name: `User ID: ${userId}` };
    }
    
    return {
      name: user.fullName || user.name || 'Unnamed User'
    };
  };

  // Custom DatePicker Input
  const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <button
      className="w-full px-4 py-2 text-left bg-white border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
      onClick={onClick}
      ref={ref}
    >
      {value || "Select Date Range"}
    </button>
  ));

  CustomDateInput.displayName = 'CustomDateInput';

  // Price calculations
  const calculatePriceStatistics = (audits = []) => {
    if (!Array.isArray(audits) || audits.length === 0) {
      return {
        maxPrice: 0,
        minPrice: 0,
        avgPrice: 0,
        competitorAvgPrice: 0,
        maxCompetitorPrice: 0
      };
    }

    const validPrices = audits
      .map(p => p?.totalStorePrice)
      .filter(p => typeof p === 'number' && !isNaN(p));
    
    const validCompPrices = audits
      .map(p => p?.totalCompetitorPrice)
      .filter(p => typeof p === 'number' && !isNaN(p));

    const average = arr => arr.reduce((a, b) => a + b, 0) / arr.length;

    return {
      maxPrice: Math.max(0, ...validPrices),
      minPrice: Math.min(...validPrices),
      avgPrice: average(validPrices),
      competitorAvgPrice: average(validCompPrices),
      maxCompetitorPrice: Math.max(0, ...validCompPrices)
    };
  };

  // Effect for initial data fetch
  useEffect(() => {
    const db = getDatabase();
    setLoading(true);
    setError(null);

    const fetchData = async () => {
      try {
        // Fetch all necessary data
        const priceAuditsRef = ref(db, 'priceAudits');
        const storesRef = ref(db, 'stores');
        const categoriesRef = ref(db, 'categories');
        const productsRef = ref(db, 'products');
        const usersRef = ref(db, 'users');

        onValue(priceAuditsRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = Object.entries(snapshot.val()).map(([id, data]) => {
              let timestamp = data.timestamp;
              if (typeof timestamp === 'number') {
                timestamp = timestamp.toString().length === 10 ? 
                  new Date(timestamp * 1000) : new Date(timestamp);
              }
              return {
                id,
                ...data,
                timestamp
              };
            });
            setPriceAudits(data);
            setPriceStats(calculatePriceStatistics(data));
          }
        });

        onValue(storesRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = Object.entries(snapshot.val()).map(([id, data]) => ({
              id,
              ...data
            }));
            setStores(data);
          }
        });

        onValue(categoriesRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = Object.entries(snapshot.val()).map(([id, data]) => ({
              id,
              ...data
            }));
            setCategories(data);
          }
        });

        onValue(productsRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = Object.entries(snapshot.val()).map(([id, data]) => ({
              id,
              ...data
            }));
            setProducts(data);
          }
        });

        onValue(usersRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = Object.entries(snapshot.val()).map(([id, data]) => ({
              id,
              ...data
            }));
            setUsers(data);
          }
        });

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();

    // Cleanup
    return () => {
      const db = getDatabase();
      off(ref(db, 'priceAudits'));
      off(ref(db, 'stores'));
      off(ref(db, 'categories'));
      off(ref(db, 'products'));
      off(ref(db, 'users'));
    };
  }, []);

  // Update filtered locations when store is selected
  useEffect(() => {
    if (!selectedStore) {
      setFilteredLocations([...new Set(stores.map(store => store.searchLocation))]);
      setSelectedLocation(null);
    } else {
      const storeLocations = stores
        .filter(store => store.id === selectedStore.value)
        .map(store => store.searchLocation);
      setFilteredLocations(storeLocations);
      setSelectedLocation(null);
    }
  }, [selectedStore, stores]);

  // Filter Data
  const filteredData = priceAudits.filter(item => {
    const itemDate = new Date(item.timestamp);
    const matchesDate = !startDate || !endDate || 
      (itemDate >= startDate && itemDate <= endDate);
    const matchesStore = !selectedStore || item.storeId === selectedStore.value;
    const storeInfo = getStoreInfo(item.storeId);
    const matchesLocation = !selectedLocation || storeInfo.location === selectedLocation.value;
    const matchesCategory = !selectedCategory || item.categoryId === selectedCategory.value;
    const matchesProduct = !selectedProduct || item.productId === selectedProduct.value;
    const matchesMerchandiser = !selectedMerchandiser || item.userId === selectedMerchandiser.value;

    return matchesDate && matchesStore && matchesLocation && 
           matchesCategory && matchesProduct && matchesMerchandiser;
  });

  // Prepare chart data
  const chartData = filteredData.map(audit => ({
    name: getProductDetails(audit.productId).productName,
    competitor: audit.competitorProductName || getProductDetails(audit.productId).competitorProductName || 'No Competitor Product',
    "Store Price": audit.totalStorePrice,
    "Competitor Price": audit.totalCompetitorPrice
  }));

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this price audit?')) {
      try {
        const db = getDatabase();
        console.log('Deleting audit with ID:', id);
        const auditRef = ref(db, 'priceAudits/' + id);
        await remove(auditRef);
        console.log('Delete successful');
      } catch (error) {
        console.error('Delete failed:', error);
        alert('Failed to delete price audit. Please try again. Error: ' + error.message);
      }
    }
  };

  // Export functions
  const exportToExcel = () => {
    const exportData = filteredData.map(audit => {
      const details = getProductDetails(audit.productId);
      const storeInfo = getStoreInfo(audit.storeId);
      const userInfo = getUserInfo(audit.userId);
      
      return {
        'Date': audit.auditDate || formatDateTime(audit.timestamp),
        'Store': storeInfo.name,
        'Location': storeInfo.location,
        'Category': getCategoryName(audit.categoryId),
        'Product': details.productName,
        'SKU': details.productNumber,
        'Store Price': audit.storePrice,
        'Store Tax': (audit.totalStorePrice - audit.storePrice).toFixed(2),
        'Total Store Price': audit.totalStorePrice,
        'Competitor Product': audit.competitorProductName || details.competitorProductName || 'No Competitor Product',
        'Competitor Price': audit.competitorPrice,
        'Competitor Tax': (audit.totalCompetitorPrice - audit.competitorPrice).toFixed(2),
        'Total Competitor Price': audit.totalCompetitorPrice,
        'Merchandiser': audit.merchandiserName || userInfo.name,
        'Notes': audit.notes || ''
      };
    });

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Price Audits');
    XLSX.writeFile(wb, 'price_audits.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF('l', 'pt', 'legal');
    
    doc.setFontSize(18);
    doc.text('Price Audit Report', 40, 40);
    
    const tableColumn = [
      'Date', 'Store', 'Location', 'Category', 'Product', 'SKU',
      'Store Price', 'Tax', 'Total', 'Competitor', 'Comp. Price',
      'Comp. Tax', 'Comp. Total', 'Merchandiser', 'Notes'
    ];

    const tableRows = filteredData.map(audit => {
      const details = getProductDetails(audit.productId);
      const storeInfo = getStoreInfo(audit.storeId);
      const userInfo = getUserInfo(audit.userId);
      
      return [
        audit.auditDate || formatDateTime(audit.timestamp),
        storeInfo.name,
        storeInfo.location,
        getCategoryName(audit.categoryId),
        details.productName,
        details.productNumber,
        `$${audit.storePrice.toFixed(2)}`,
        `$${(audit.totalStorePrice - audit.storePrice).toFixed(2)}`,
        `$${audit.totalStorePrice.toFixed(2)}`,
        audit.competitorProductName || details.competitorProductName || 'No Competitor Product',
        `$${audit.competitorPrice.toFixed(2)}`,
        `$${(audit.totalCompetitorPrice - audit.competitorPrice).toFixed(2)}`,
        `$${audit.totalCompetitorPrice.toFixed(2)}`,
        audit.merchandiserName || userInfo.name,
        audit.notes || ''
      ];
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 60,
      theme: 'grid',
      styles: { fontSize: 8, cellPadding: 2 },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 80 },
        2: { cellWidth: 70 },
        3: { cellWidth: 80 },
        4: { cellWidth: 100 },
        5: { cellWidth: 60 },
        6: { cellWidth: 60 },
        7: { cellWidth: 50 },
        8: { cellWidth: 50 },
        9: { cellWidth: 80 },
        10: { cellWidth: 60 },
        11: { cellWidth: 50 },
        12: { cellWidth: 50 },
        13: { cellWidth: 80 },
        14: { cellWidth: 100 }
      }
    });

    doc.save('price_audit_report.pdf');
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow">
        <div className="p-6">
          <h1 className="text-2xl font-bold mb-6">Price Audit Dashboard</h1>

          {/* Filters Section */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
            <div className="col-span-full md:col-span-1">
              <DatePicker
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={setDateRange}
                customInput={<CustomDateInput />}
                placeholderText="Select Date Range"
                className="w-full"
              />
            </div>

            <Select
              value={selectedStore}
              onChange={setSelectedStore}
              options={stores.map(store => ({
                value: store.id,
                label: store.storeName
              }))}
              placeholder="Select Store"
              isClearable
              className="react-select-container"
            />

            <Select
              value={selectedLocation}
              onChange={setSelectedLocation}
              options={filteredLocations.map(location => ({
                value: location,
                label: location
              }))}
              placeholder="Select Location"
              isClearable
              isDisabled={!selectedStore}
              className="react-select-container"
            />

            <Select
              value={selectedCategory}
              onChange={setSelectedCategory}
              options={categories.map(category => ({
                value: category.id,
                label: category.categoryName
              }))}
              placeholder="Select Category"
              isClearable
              className="react-select-container"
            />

            <Select
              value={selectedProduct}
              onChange={setSelectedProduct}
              options={products.map(product => ({
                value: product.id,
                label: product.productName
              }))}
              placeholder="Select Product"
              isClearable
              className="react-select-container"
            />

            <Select
              value={selectedMerchandiser}
              onChange={setSelectedMerchandiser}
              options={users.map(user => ({
                value: user.id,
                label: user.fullName
              }))}
              placeholder="Select Merchandiser"
              isClearable
              className="react-select-container"
            />
          </div>

          {/* Price Index Card */}
          <PriceIndexCard priceData={chartData} />

          {/* Price Comparison Graph */}
          <div className="bg-white p-4 rounded-lg shadow mb-6">
            <div className="text-lg font-medium mb-4">Price Comparison</div>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={chartData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 100 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    angle={-45}
                    textAnchor="end"
                    height={100}
                    interval={0}
                    tick={{ fontSize: 12 }}
                  />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Store Price" fill="#8884d8" />
                  <Bar dataKey="Competitor Price" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Export Buttons */}
          <div className="flex gap-4 mb-6">
            <button
              onClick={exportToExcel}
              className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded transition-colors duration-200 flex items-center gap-2"
            >
              <Download className="h-5 w-5" />
              Export to Excel
            </button>
            <button
              onClick={exportToPDF}
              className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition-colors duration-200 flex items-center gap-2"
            >
              <FileText className="h-5 w-5" />
              Export to PDF
            </button>
          </div>

          {/* Price Statistics Cards */}
          <ProductPriceCards priceData={chartData} />

          {/* Data Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Store</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">SKU</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Store Price</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tax</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Price</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Competitor Product</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Competitor Price</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Comp. Tax</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Comp. Total</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Merchandiser</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Notes</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredData.map((audit) => {
                  const details = getProductDetails(audit.productId);
                  const storeInfo = getStoreInfo(audit.storeId);
                  const userInfo = getUserInfo(audit.userId);
                  
                  return (
                    <tr key={audit.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {formatDateTime(audit.timestamp)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {storeInfo.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {storeInfo.location}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {getCategoryName(audit.categoryId)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {details.productName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {details.productNumber}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        ${audit.storePrice.toFixed(2)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        ${(audit.totalStorePrice - audit.storePrice).toFixed(2)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        ${audit.totalStorePrice.toFixed(2)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {audit.competitorProductName || details.competitorProductName || 'No Competitor Product'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        ${audit.competitorPrice.toFixed(2)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        ${(audit.totalCompetitorPrice - audit.competitorPrice).toFixed(2)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        ${audit.totalCompetitorPrice.toFixed(2)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {audit.merchandiserName || userInfo.name}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        <div className="max-w-xs truncate">{audit.notes || ''}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        <button
                          onClick={() => handleDelete(audit.id)}
                          className="text-red-600 hover:text-red-900 hover:underline focus:outline-none"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
                {filteredData.length === 0 && (
                  <tr>
                    <td colSpan="16" className="px-6 py-4 text-center text-gray-500">
                      No price audit data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceAudit;
import React from 'react';
import { Clipboard, HandshakeIcon, DollarSign, Megaphone } from 'lucide-react';
import SmerchLogo from '../assets/SmerchLogo.png'; // Import the logo

const FeatureCard = ({ title, icon: Icon, description }) => (
  <div className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center">
    <Icon className="w-12 h-12 text-blue-500 mb-4" />
    <h2 className="text-xl font-bold mb-2">{title}</h2>
    <p className="text-gray-600">{description}</p>
  </div>
);

const Dashboard = () => {
  const features = [
    {
      title: "Planogram Tracker",
      icon: Clipboard,
      description: "We will provide visual representation of your store's products."
    },
    {
      title: "Sales Support",
      icon: HandshakeIcon,
      description: "We will increase visibility, availability, and overall presence of your brands."
    },
    {
      title: "Store Audits",
      icon: DollarSign,
      description: "Timestamping, photo and signature verification make it simple to validate work"
    },
    {
      title: "Marketing Activity",
      icon: Megaphone,
      description: "Track your marketing activity & see what your competitors are doing."
    }
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="text-center mb-12">
        <img src={SmerchLogo} alt="Smerch Logo" className="mx-auto mb-6 h-20" /> {/* Use the imported logo and set a height */}
        <h2 className="text-2xl font-bold mb-4">A message from our CEO</h2>
        <p className="text-gray-600 max-w-2xl mx-auto">
          Our technology powers the retail intelligence you need to drive your business with our all-in-one solution for retail merchandising execution, sales support, in-store executions, team management and vendor collaboration. <br></br>Our motto is <strong>"Excellence across shelves start here"</strong>.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
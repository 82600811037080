import React from 'react';

const PriceStatCard = ({ title, value, description }) => (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex flex-row items-center justify-between pb-2">
        <h3 className="text-sm font-medium text-gray-600">{title}</h3>
      </div>
      <div className="text-2xl font-bold text-gray-900">${value.toFixed(2)}</div>
      {description && (
        <p className="text-xs text-gray-500 mt-1">{description}</p>
      )}
    </div>
  );
  
  const ProductPriceCards = ({ priceData }) => {
    // Calculate statistics
    const getStats = () => {
      if (!priceData || priceData.length === 0) {
        return {
          maxPrice: 0,
          minPrice: 0,
          avgPrice: 0,
          maxCompPrice: 0,
          minCompPrice: 0,
          avgCompPrice: 0
        };
      }
  
      const storePrices = priceData.map(item => item["Store Price"]).filter(price => !isNaN(price) && price != null);
      const compPrices = priceData.map(item => item["Competitor Price"]).filter(price => !isNaN(price) && price != null);
  
      const average = arr => arr.length > 0 ? arr.reduce((a, b) => a + b, 0) / arr.length : 0;
  
      return {
        maxPrice: Math.max(0, ...storePrices),
        minPrice: Math.min(...storePrices),
        avgPrice: average(storePrices),
        maxCompPrice: Math.max(0, ...compPrices),
        minCompPrice: Math.min(...compPrices),
        avgCompPrice: average(compPrices)
      };
    };
  
    const stats = getStats();
  
    return (
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 mb-6">
        <PriceStatCard
          title="Maximum Product Price"
          value={stats.maxPrice}
          description="Highest store price recorded"
        />
        <PriceStatCard
          title="Minimum Product Price"
          value={stats.minPrice}
          description="Lowest store price recorded"
        />
        <PriceStatCard
          title="Average Product Price"
          value={stats.avgPrice}
          description="Average store price"
        />
        <PriceStatCard
          title="Maximum Competitor Price"
          value={stats.maxCompPrice}
          description="Highest competitor price recorded"
        />
        <PriceStatCard
          title="Minimum Competitor Price"
          value={stats.minCompPrice}
          description="Lowest competitor price recorded"
        />
        <PriceStatCard
          title="Average Competitor Price"
          value={stats.avgCompPrice}
          description="Average competitor price"
        />
      </div>
    );
  };
  
  export default ProductPriceCards;
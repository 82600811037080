import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { 
  BarChart, 
  Bar, 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer 
} from 'recharts';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { ArrowDown, ArrowUp, Minus, Info, Download, FileText } from 'lucide-react';
import "react-datepicker/dist/react-datepicker.css";

const PriceAnalytics = () => {
  // State management
  const [priceMetrics, setPriceMetrics] = useState({
    storePrice: { max: 0, min: 0, avg: 0 },
    competitorPrice: { max: 0, min: 0, avg: 0 },
    priceComparisonIndex: 0
  });
  const [priceComparison, setPriceComparison] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Filter states
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedMerchandiser, setSelectedMerchandiser] = useState(null);
  
  // Data states
  const [stores, setStores] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  
  const reportRef = React.useRef(null);

  // Helper functions
  const formatNumber = (number, decimals = 2) => {
    if (number === null || number === undefined || isNaN(number)) return '0';
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    }).format(number);
  };

  const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString();
  };

  const calculatePriceComparisonIndex = (data) => {
    if (!data || data.length === 0) return 0;
    
    const maxStorePrice = Math.max(...data.map(item => item.storePrice || 0));
    const maxCompPrice = Math.max(...data.map(item => item.competitorPrice || 0));
    
    if (maxCompPrice === 0) return 0;
    return ((maxStorePrice / maxCompPrice) * 100) - 100;
  };

  // Export functions
  const exportToExcel = () => {
    const exportData = priceComparison.map(item => ({
      Date: formatDate(item.timestamp),
      Product: item.productName,
      'Store Price': item.storePrice,
      'Competitor Price': item.competitorPrice,
      'Price Difference': item.priceDifference,
      'Price Status': item.priceStatus
    }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(exportData);
    XLSX.utils.book_append_sheet(wb, ws, "Price Analysis");
    XLSX.writeFile(wb, `price_analysis_${formatDate(startDate)}_to_${formatDate(endDate)}.xlsx`);
  };

  const exportToPDF = async () => {
    if (!reportRef.current) return;

    try {
      const canvas = await html2canvas(reportRef.current, {
        scale: 2,
        logging: false,
        useCORS: true
      });
      
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`price_analysis_${formatDate(startDate)}_to_${formatDate(endDate)}.pdf`);
    } catch (err) {
      console.error('Error generating PDF:', err);
    }
  };

  // Data fetching effect
  useEffect(() => {
    const db = getDatabase();
    setLoading(true);
    setError(null);

    try {
      const priceAuditsRef = ref(db, 'priceAudits');
      const storesRef = ref(db, 'stores');
      const categoriesRef = ref(db, 'categories');
      const productsRef = ref(db, 'products');
      const usersRef = ref(db, 'users');

      // Fetch price audits
      onValue(priceAuditsRef, (snapshot) => {
        if (!snapshot.exists()) {
          setError('No price audit data found');
          setLoading(false);
          return;
        }

        let priceData = Object.values(snapshot.val());
        
        // Apply filters
        if (startDate && endDate) {
          priceData = priceData.filter(item => {
            const itemDate = new Date(item.timestamp);
            return itemDate >= startDate && itemDate <= endDate;
          });
        }

        if (selectedStore) {
          priceData = priceData.filter(item => item.storeId === selectedStore.value);
        }

        if (selectedCategory) {
          priceData = priceData.filter(item => item.categoryId === selectedCategory.value);
        }

        if (selectedProduct) {
          priceData = priceData.filter(item => item.productId === selectedProduct.value);
        }

        if (selectedMerchandiser) {
          priceData = priceData.filter(item => item.userId === selectedMerchandiser.value);
        }

        // Calculate metrics
        const metrics = priceData.reduce((acc, item) => {
          // Store prices
          acc.storePrice.max = Math.max(acc.storePrice.max, item.storePrice || 0);
          acc.storePrice.min = acc.storePrice.min === 0 ? 
            item.storePrice : Math.min(acc.storePrice.min, item.storePrice || 0);
          acc.storePrice.total += item.storePrice || 0;

          // Competitor prices
          acc.competitorPrice.max = Math.max(acc.competitorPrice.max, item.competitorPrice || 0);
          acc.competitorPrice.min = acc.competitorPrice.min === 0 ? 
            item.competitorPrice : Math.min(acc.competitorPrice.min, item.competitorPrice || 0);
          acc.competitorPrice.total += item.competitorPrice || 0;

          return acc;
        }, {
          storePrice: { max: 0, min: 0, total: 0 },
          competitorPrice: { max: 0, min: 0, total: 0 }
        });

        const count = priceData.length || 1;
        
        setPriceMetrics({
          storePrice: {
            max: metrics.storePrice.max,
            min: metrics.storePrice.min,
            avg: metrics.storePrice.total / count
          },
          competitorPrice: {
            max: metrics.competitorPrice.max,
            min: metrics.competitorPrice.min,
            avg: metrics.competitorPrice.total / count
          },
          priceComparisonIndex: calculatePriceComparisonIndex(priceData)
        });

        setPriceComparison(priceData);
        setLoading(false);
      });

      // Fetch stores
      onValue(storesRef, (snapshot) => {
        if (snapshot.exists()) {
          const storesData = Object.entries(snapshot.val()).map(([id, data]) => ({
            value: id,
            label: data.storeName,
            location: data.searchLocation
          }));
          setStores(storesData);
        }
      });

      // Fetch categories
      onValue(categoriesRef, (snapshot) => {
        if (snapshot.exists()) {
          const categoriesData = Object.entries(snapshot.val()).map(([id, data]) => ({
            value: id,
            label: data.categoryName
          }));
          setCategories(categoriesData);
        }
      });

      // Fetch products
      onValue(productsRef, (snapshot) => {
        if (snapshot.exists()) {
          const productsData = Object.entries(snapshot.val()).map(([id, data]) => ({
            value: id,
            label: data.productName
          }));
          setProducts(productsData);
        }
      });

      // Fetch users
      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const usersData = Object.entries(snapshot.val())
            .filter(([_, user]) => user.role === 'Merchandiser' || user.role === 'Merchandiser and Manager')
            .map(([id, data]) => ({
              value: id,
              label: data.fullName
            }));
          setUsers(usersData);
        }
      });

    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Error fetching data. Please try again later.');
      setLoading(false);
    }
  }, [startDate, endDate, selectedStore, selectedCategory, selectedProduct, selectedMerchandiser]);

  // Update filtered locations
  useEffect(() => {
    if (!selectedStore) {
      setFilteredLocations([...new Set(stores.map(store => store.location))]);
      setSelectedLocation(null);
    } else {
      const storeLocations = stores
        .filter(store => store.value === selectedStore.value)
        .map(store => store.location);
      setFilteredLocations(storeLocations);
      setSelectedLocation(null);
    }
  }, [selectedStore, stores]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 p-4">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Filters Section */}
      <div className="bg-white rounded-lg shadow p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">Filters</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <DatePicker
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={setDateRange}
            className="w-full px-4 py-2 border rounded"
            placeholderText="Select Date Range"
          />
          
          <Select
            value={selectedStore}
            onChange={setSelectedStore}
            options={stores}
            placeholder="Select Store"
            isClearable
          />

          <Select
            value={selectedLocation}
            onChange={setSelectedLocation}
            options={filteredLocations.map(location => ({
              value: location,
              label: location
            }))}
            placeholder="Select Location"
            isClearable
            isDisabled={!selectedStore}
          />

          <Select
            value={selectedCategory}
            onChange={setSelectedCategory}
            options={categories}
            placeholder="Select Category"
            isClearable
          />

          <Select
            value={selectedProduct}
            onChange={setSelectedProduct}
            options={products}
            placeholder="Select Product"
            isClearable
          />

          <Select
            value={selectedMerchandiser}
            onChange={setSelectedMerchandiser}
            options={users}
            placeholder="Select Merchandiser"
            isClearable
          />
        </div>
      </div>

      <div ref={reportRef}>
        {/* Price Comparison Index Card */}
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
  <div className="border-b pb-2">
    <div className="flex items-center justify-between">
      <h3 className="text-lg font-bold text-gray-700">Price Comparison Index</h3>
      <div className="group relative">
        <Info className="h-4 w-4 text-gray-400 cursor-help" />
        <div className="hidden group-hover:block absolute z-10 w-72 p-2 text-sm bg-gray-800 text-white rounded shadow-lg -right-2 top-6">
          Price Comparison Index = (Maximum Store Company Price/Maximum Competitor Price *100) - 100
        </div>
      </div>
    </div>
  </div>
  <div className="mt-4">
    <div className="text-2xl font-bold text-blue-600">
      {formatNumber(priceMetrics.priceComparisonIndex)}%
    </div>
    <div className="text-gray-600 mt-2">
      {priceMetrics.priceComparisonIndex === 0 
        ? "Equal to Competitor Prices"
        : priceMetrics.priceComparisonIndex > 0 
          ? "Higher than Competitor Prices"
          : "Lower than Competitor Prices"}
    </div>
  </div>
</div>

        {/* Price Metrics Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
          {/* Store Price Metrics */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-lg font-bold text-gray-700 border-b pb-2">Store Price Metrics</h3>
            <div className="space-y-4 mt-4">
              <div>
                <div className="text-sm text-gray-600">Maximum Price</div>
                <div className="text-xl font-bold text-green-600">
                  ${formatNumber(priceMetrics.storePrice.max)}
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-600">Minimum Price</div>
                <div className="text-xl font-bold text-red-600">
                  ${formatNumber(priceMetrics.storePrice.min)}
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-600">Average Price</div>
                <div className="text-xl font-bold text-blue-600">
                  ${formatNumber(priceMetrics.storePrice.avg)}
                </div>
              </div>
            </div>
          </div>

          {/* Competitor Price Metrics */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-lg font-bold text-gray-700 border-b pb-2">Competitor Price Metrics</h3>
            <div className="space-y-4 mt-4">
              <div>
                <div className="text-sm text-gray-600">Maximum Price</div>
                <div className="text-xl font-bold text-green-600">
                  ${formatNumber(priceMetrics.competitorPrice.max)}
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-600">Minimum Price</div>
                <div className="text-xl font-bold text-red-600">
                  ${formatNumber(priceMetrics.competitorPrice.min)}
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-600">Average Price</div>
                <div className="text-xl font-bold text-blue-600">
                  ${formatNumber(priceMetrics.competitorPrice.avg)}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Price Comparison Chart */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-lg font-bold text-gray-700 border-b pb-4">Price Comparison</h3>
          <div className="mt-4 h-80">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={priceComparison}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="productName"
                  angle={-45}
                  textAnchor="end"
                  height={80}
                  interval={0}
                  tick={{fontSize: 12}}
                />
                <YAxis />
                <Tooltip 
                  formatter={(value) => `$${formatNumber(value)}`}
                />
                <Legend />
                <Bar 
                  dataKey="storePrice" 
                  name="Store Price" 
                  fill="#3b82f6"
                />
                <Bar 
                  dataKey="competitorPrice" 
                  name="Competitor Price" 
                  fill="#10b981"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Price Comparison Table */}
        <div className="bg-white rounded-lg shadow-md p-6 mt-8">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-bold text-gray-700">Price Comparison Details</h3>
            <div className="flex gap-4">
              <button
                onClick={exportToExcel}
                className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition-colors"
              >
                <Download className="h-4 w-4" />
                Export to Excel
              </button>
              <button
                onClick={exportToPDF}
                className="flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
              >
                <FileText className="h-4 w-4" />
                Export to PDF
              </button>
            </div>
          </div>
          
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 text-left">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Product</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Store Price</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Competitor Price</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Difference</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {priceComparison.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">{item.productName}</td>
                    <td className="px-6 py-4 whitespace-nowrap">${formatNumber(item.storePrice)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">${formatNumber(item.competitorPrice)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-1">
                        {item.storePrice > item.competitorPrice ? (
                        <ArrowUp className="w-4 h-4 text-red-500" />
                        ) : item.storePrice < item.competitorPrice ? (
                        <ArrowDown className="w-4 h-4 text-green-500" />
                        ) : (
                        <Minus className="w-4 h-4 text-gray-500" />
                        )}
                        ${formatNumber(Math.abs(item.competitorPrice - item.storePrice))}
                    </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`inline-flex px-2 text-xs font-semibold leading-5 rounded-full ${
                        item.priceStatus === 'Higher' ? 'bg-red-100 text-red-800' :
                        item.priceStatus === 'Lower' ? 'bg-green-100 text-green-800' :
                        'bg-gray-100 text-gray-800'
                      }`}>
                        {item.priceStatus}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceAnalytics;
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ref, onValue } from 'firebase/database';
import { db } from '../firebase';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import { Clock, Store } from 'lucide-react';
import { 
  ComposedChart, 
  Line, 
  Bar,
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend,
  ResponsiveContainer 
} from 'recharts';
import SignatureImage from './SignatureImage';

const MerchandiserTimeSheet = () => {
  const [filters, setFilters] = useState({
    dateRange: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
    store: null,
    channel: null,
    merchandiser: null,
    parish: null
  });
  
  const [timeSheetData, setTimeSheetData] = useState([]);
  const [stores, setStores] = useState([]);
  const [channels, setChannels] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [merchandisers, setMerchandisers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    setError(null);

    const timesheetsRef = ref(db, 'timesheets');
    const storesRef = ref(db, 'stores');
    const usersRef = ref(db, 'users');

    const timesheetsListener = onValue(timesheetsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const formattedData = Object.entries(data).map(([id, value]) => ({
          id,
          ...value,
          records: value.records || [],
          createdAt: value.createdAt || Date.now(),
          checkInTime: value.checkInTime || 0,
          checkOutTime: value.checkOutTime || 0,
          totalTime: value.totalTime || 0,
          breakTime: value.breakTime || 0,
          lunchTime: value.lunchTime || 0
        }));
        setTimeSheetData(formattedData);
      } else {
        setTimeSheetData([]);
      }
      setIsLoading(false);
    }, (error) => {
      setError("Error loading timesheet data: " + error.message);
      setIsLoading(false);
    });

    const storesListener = onValue(storesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const storeOptions = Object.entries(data).map(([id, store]) => ({
          value: id,
          label: store.storeName,
          channel: store.channel,
          parish: store.parish,
          location: store.searchLocation
        }));
        setStores(storeOptions);
        
        const uniqueChannels = [...new Set(storeOptions.map(store => store.channel))];
        const uniqueParishes = [...new Set(storeOptions.map(store => store.parish))];
        
        setChannels(uniqueChannels.map(channel => ({
          value: channel,
          label: channel
        })));
        
        setParishes(uniqueParishes.map(parish => ({
          value: parish,
          label: parish
        })));
      }
    }, (error) => {
      setError("Error loading store data: " + error.message);
    });

    const usersListener = onValue(usersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const merchandiserOptions = Object.entries(data)
          .filter(([_, user]) => 
            user.role === 'Merchandiser' || 
            user.role === 'Merchandiser and Manager'
          )
          .map(([id, user]) => ({
            value: id,
            label: user.fullName,
            companyId: user.companyId
          }));
        setMerchandisers(merchandiserOptions);
      }
    }, (error) => {
      setError("Error loading user data: " + error.message);
    });

    return () => {
      timesheetsListener();
      storesListener();
      usersListener();
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const filteredData = useMemo(() => {
    return timeSheetData.filter(timesheet => {
      const timesheetDate = new Date(timesheet.createdAt);
      const [startDate, endDate] = filters.dateRange;
      
      return (
        (!startDate || timesheetDate >= startDate) &&
        (!endDate || timesheetDate <= endDate) &&
        (!filters.store || timesheet.storeId === filters.store.value) &&
        (!filters.channel || timesheet.storeChannel === filters.channel.value) &&
        (!filters.parish || timesheet.storeParish === filters.parish.value) &&
        (!filters.merchandiser || timesheet.userId === filters.merchandiser.value)
      );
    }).sort((a, b) => b.createdAt - a.createdAt);
  }, [timeSheetData, filters]);

  const metrics = useMemo(() => {
    // Calculate total hours worked by summing the difference between checkout and checkin times
    const totalHoursWorked = filteredData.reduce((sum, timesheet) => {
      if (timesheet.checkOutTime && timesheet.checkInTime) {
        const hours = (timesheet.checkOutTime - timesheet.checkInTime) / (1000 * 60 * 60);
        return sum + hours;
      }
      return sum;
    }, 0);

    // Convert total hours to hours and minutes format
    const totalHours = Math.floor(totalHoursWorked);
    const totalMinutes = Math.round((totalHoursWorked - totalHours) * 60);
    
    const uniqueStores = new Set(filteredData.map(t => t.storeId)).size;
    
    const graphData = filteredData.reduce((acc, timesheet) => {
      const date = new Date(timesheet.createdAt).toLocaleDateString();
      const merchandiserName = merchandisers.find(m => m.value === timesheet.userId)?.label || 'Unknown';
      const hours = timesheet.checkOutTime && timesheet.checkInTime ? 
        (timesheet.checkOutTime - timesheet.checkInTime) / (1000 * 60 * 60) : 0;

      const existingDate = acc.find(d => d.date === date);
      if (existingDate) {
        existingDate.totalTime += hours;
        existingDate[`${merchandiserName}_time`] = (existingDate[`${merchandiserName}_time`] || 0) + hours;
      } else {
        const newEntry = {
          date,
          totalTime: hours,
          [`${merchandiserName}_time`]: hours
        };
        acc.push(newEntry);
      }
      return acc;
    }, []);

    return {
      totalTimeFormatted: `${totalHours}h ${totalMinutes}m`,
      totalHoursWorked,
      uniqueStores,
      graphData: graphData.sort((a, b) => new Date(a.date) - new Date(b.date))
    };
  }, [filteredData, merchandisers]);

  const formatTooltipTime = (value) => {
    if (!value || isNaN(value)) return '0h 0m';
    const hours = Math.floor(value);
    const minutes = Math.round((value - hours) * 60);
    return `${hours}h ${minutes}m`;
  };

  // Get unique merchandisers for creating bars
  const uniqueMerchandisers = useMemo(() => {
    return Array.from(new Set(filteredData.map(t => 
      merchandisers.find(m => m.value === t.userId)?.label
    ).filter(Boolean)));
  }, [filteredData, merchandisers]);

  // Generate colors for each merchandiser
  const merchandiserColors = useMemo(() => {
    const colors = ['#4f46e5', '#06b6d4', '#10b981', '#f59e0b', '#ef4444'];
    return uniqueMerchandisers.reduce((acc, merchandiser, index) => {
      acc[merchandiser] = colors[index % colors.length];
      return acc;
    }, {});
  }, [uniqueMerchandisers]);

  const formatTime = (timestamp) => {
    if (!timestamp) return 'N/A';
    return new Date(timestamp).toLocaleTimeString();
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    return new Date(timestamp).toLocaleDateString();
  };

  const formatGPS = (lat, long) => {
    if (!lat || !long) return 'N/A';
    return `${lat.toFixed(6)}, ${long.toFixed(6)}`;
  };

  const calculateHoursWorked = (checkIn, checkOut) => {
    if (!checkIn || !checkOut) return 'N/A';
    const diff = (checkOut - checkIn) / (1000 * 60 * 60);
    return diff.toFixed(2) + ' hrs';
  };

  const formatDuration = (seconds) => {
    if (!seconds) return '0h 0m';
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  const getBreakTimes = (records) => {
    if (!records) return { start: 'N/A', end: 'N/A' };
    const startBreak = records.find(r => r.status === 'Start Break');
    const endBreak = records.find(r => r.status === 'Stop Break');
    return {
      start: startBreak ? startBreak.time : 'N/A',
      end: endBreak ? endBreak.time : 'N/A'
    };
  };

  const getLunchTimes = (records) => {
    if (!records) return { start: 'N/A', end: 'N/A' };
    const startLunch = records.find(r => r.status === 'Start Lunch');
    const endLunch = records.find(r => r.status === 'Stop Lunch');
    return {
      start: startLunch ? startLunch.time : 'N/A',
      end: endLunch ? endLunch.time : 'N/A'
    };
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="bg-red-50 p-4 rounded-lg text-red-500">
          <h3 className="font-medium">Error Loading Data</h3>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Merchandiser Time Sheet</h1>
      
      {/* Filters */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mb-8">
        <DatePicker
          selectsRange={true}
          startDate={filters.dateRange[0]}
          endDate={filters.dateRange[1]}
          onChange={(dates) => setFilters(prev => ({ ...prev, dateRange: dates }))}
          className="w-full p-2 border rounded"
          placeholderText="Select Date Range"
        />
        <Select
          value={filters.store}
          onChange={(option) => setFilters(prev => ({ ...prev, store: option }))}
          options={stores}
          placeholder="Select Store"
          isClearable
        />
        <Select
          value={filters.channel}
          onChange={(option) => setFilters(prev => ({ ...prev, channel: option }))}
          options={channels}
          placeholder="Select Channel"
          isClearable
        />
        <Select
          value={filters.parish}
          onChange={(option) => setFilters(prev => ({ ...prev, parish: option }))}
          options={parishes}
          placeholder="Select Parish"
          isClearable
        />
        <Select
          value={filters.merchandiser}
          onChange={(option) => setFilters(prev => ({ ...prev, merchandiser: option }))}
          options={merchandisers}
          placeholder="Select Merchandiser"
          isClearable
        />
      </div>

     {/* Metric Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-gray-600">Total Time</h3>
            <Clock className="h-5 w-5 text-blue-500" />
          </div>
          <p className="text-2xl font-bold">{metrics.totalTimeFormatted}</p>
        </div>
        
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-gray-600">Stores Visited</h3>
            <Store className="h-5 w-5 text-blue-500" />
          </div>
          <p className="text-2xl font-bold">{metrics.uniqueStores}</p>
        </div>
      </div>

      {/* Time Chart */}
    <div className="bg-white rounded-lg shadow p-6 mb-8">
      <h3 className="text-lg font-semibold mb-4">Time Distribution</h3>
      <div className="h-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={metrics.graphData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis label={{ value: 'Hours', angle: -90, position: 'insideLeft' }} />
            <Tooltip 
              formatter={(value, name) => {
                if (name === 'Total Time') return formatTooltipTime(value);
                // Remove '_time' suffix from merchandiser names in tooltip
                const merchandiserName = name.replace('_time', '');
                return formatTooltipTime(value);
              }}
              labelFormatter={(label) => `Date: ${label}`}
            />
            <Legend />
            {uniqueMerchandisers.map((merchandiser) => (
              <Bar
                key={merchandiser}
                dataKey={`${merchandiser}_time`}
                name={merchandiser}
                fill={merchandiserColors[merchandiser]}
                barSize={20}
              />
            ))}
            <Line
              type="monotone"
              dataKey="totalTime"
              name="Total Time"
              stroke="#3b82f6"
              strokeWidth={2}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>

      {/* Comprehensive Table */}
      <div className="bg-white rounded-lg shadow overflow-x-auto">
        <table className="min-w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Store</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Location</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Channel</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Parish</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Check-in Time</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Check-in GPS Location</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Start Break Time</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">End Break Time</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Start Lunch Time</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">End Lunch Time</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Check-out Time</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Check-out GPS Location</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Hours Worked</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Merchandiser</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Store Manager</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Signature</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Time of Signature</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Action</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredData.map((timesheet) => {
              const breakTimes = getBreakTimes(timesheet.records);
              const lunchTimes = getLunchTimes(timesheet.records);
              const merchandiserName = merchandisers.find(m => m.value === timesheet.userId)?.label || 'N/A';
              
              return (
                <tr key={timesheet.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {formatDate(timesheet.createdAt)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {timesheet.storeName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {timesheet.storeLocation}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {timesheet.storeChannel}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {timesheet.storeParish}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {formatTime(timesheet.checkInTime)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {formatGPS(timesheet.gpsLatitude, timesheet.gpsLongitude)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {breakTimes.start}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {breakTimes.end}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {lunchTimes.start}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {lunchTimes.end}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {formatTime(timesheet.checkOutTime)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {formatGPS(timesheet.gpsLatitude, timesheet.gpsLongitude)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {calculateHoursWorked(timesheet.checkInTime, timesheet.checkOutTime)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {merchandiserName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {timesheet.storeManagerName || 'N/A'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <SignatureImage signatureUrl={timesheet.signatureUrl} />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {formatTime(timesheet.createdAt)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {timesheet.status || 'N/A'}
                  </td>
                </tr>
              );
            })}
            {filteredData.length === 0 && (
              <tr>
                <td colSpan="19" className="px-6 py-4 text-center text-gray-500">
                  No timesheet records found
                </td>
              </tr>
            )}
          </tbody>
          <tfoot className="bg-gray-50">
            <tr>
              <td colSpan="13" className="px-6 py-4 text-sm font-medium text-gray-900">
                Total Records: {filteredData.length}
              </td>
              <td colSpan="6" className="px-6 py-4 text-sm font-medium text-gray-900">
                Total Time Worked: {metrics.totalTimeFormatted}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      
    </div>
  );
};

export default MerchandiserTimeSheet;  
import React, { useState, useEffect, useCallback } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import DatePicker from 'react-datepicker';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { AlertCircle, PackageCheck } from 'lucide-react';
import "react-datepicker/dist/react-datepicker.css";

const COLORS = ['#3b82f6', '#10b981', '#f59e0b', '#ef4444', '#8b5cf6'];

const ProductAnalytics = () => {
  const [productMetrics, setProductMetrics] = useState({
    totalProducts: 0,
    productsOutOfStock: 0,
    damagedProducts: 0,
    expiredProducts: 0,
    categoriesWithIssues: new Set(),
    categoryDistribution: {},
    productStatus: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const reportRef = React.useRef(null);

  const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString();
  };

  const formatNumber = (number, decimals = 0) => {
    if (number === null || number === undefined || isNaN(number)) {
      return '0';
    }
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    }).format(parseFloat(number));
  };

  // Move filterDataByDateRange before useEffect
  const filterDataByDateRange = useCallback((data) => {
    if (!startDate || !endDate) return data;
    return data.filter(item => {
      const itemDate = new Date(item.timestamp);
      return itemDate >= startDate && itemDate <= endDate;
    });
  }, [startDate, endDate]);

  const exportToExcel = () => {
    const metricsData = [
      ['Product Analytics Report'],
      [`Period: ${formatDate(startDate)} to ${formatDate(endDate)}`],
      [''],
      ['Overall Metrics', 'Count'],
      ['Total Products', productMetrics.totalProducts],
      ['Products Out of Stock', productMetrics.productsOutOfStock],
      ['Damaged Products', productMetrics.damagedProducts],
      ['Expired Products', productMetrics.expiredProducts],
      ['Categories with Issues', productMetrics.categoriesWithIssues.size],
      ['']
    ];

    const distributionData = Object.entries(productMetrics.categoryDistribution)
      .map(([category, count]) => ({
        Category: category,
        'Product Count': count
      }));

    const statusData = productMetrics.productStatus.map(item => ({
      Product: item.name,
      Status: item.status,
      Category: item.category
    }));

    const wb = XLSX.utils.book_new();
    
    const wsMetrics = XLSX.utils.aoa_to_sheet(metricsData);
    XLSX.utils.book_append_sheet(wb, wsMetrics, "Product Metrics");
    
    const wsDistribution = XLSX.utils.json_to_sheet(distributionData);
    XLSX.utils.book_append_sheet(wb, wsDistribution, "Category Distribution");

    const wsStatus = XLSX.utils.json_to_sheet(statusData);
    XLSX.utils.book_append_sheet(wb, wsStatus, "Product Status");

    XLSX.writeFile(wb, `product_analytics_${formatDate(startDate)}_to_${formatDate(endDate)}.xlsx`);
  };

  const exportToPDF = async () => {
    if (!reportRef.current) return;

    try {
      const pdf = new jsPDF('p', 'mm', 'a4');
      const canvas = await html2canvas(reportRef.current, {
        scale: 2,
        logging: false,
        useCORS: true
      });

      const imgData = canvas.toDataURL('image/png');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`product_analytics_${formatDate(startDate)}_to_${formatDate(endDate)}.pdf`);
    } catch (err) {
      console.error('Error generating PDF:', err);
    }
  };

  useEffect(() => {
    const db = getDatabase();
    setLoading(true);
    setError(null);

    try {
      const productRef = ref(db, 'products');
      const outOfStockRef = ref(db, 'outOfStock');
      const expiryRef = ref(db, 'productExpiry');
      const categoriesRef = ref(db, 'categories');

      Promise.all([
        new Promise(resolve => onValue(productRef, resolve)),
        new Promise(resolve => onValue(outOfStockRef, resolve)),
        new Promise(resolve => onValue(expiryRef, resolve)),
        new Promise(resolve => onValue(categoriesRef, resolve))
      ]).then(([productsSnapshot, outOfStockSnapshot, expirySnapshot, categoriesSnapshot]) => {
        const products = productsSnapshot.val() || {};
        const outOfStock = outOfStockSnapshot.val() || {};
        const expiry = expirySnapshot.val() || {};
        const categories = categoriesSnapshot.val() || {};

        const filteredOutOfStock = filterDataByDateRange(Object.values(outOfStock));
        const filteredExpiry = filterDataByDateRange(Object.values(expiry));

        const categoryDistribution = Object.values(products).reduce((acc, product) => {
          const categoryName = categories[product.categoryId]?.categoryName || 'Unknown';
          acc[categoryName] = (acc[categoryName] || 0) + 1;
          return acc;
        }, {});

        const categoriesWithIssues = new Set([
          ...filteredOutOfStock.map(item => item.categoryId),
          ...filteredExpiry.map(item => item.categoryId)
        ]);

        const productStatus = Object.values(products).map(product => ({
          name: product.productName,
          category: categories[product.categoryId]?.categoryName || 'Unknown',
          status: filteredOutOfStock.some(item => item.productId === product.id) ? 
            'Out of Stock' : 
            filteredExpiry.some(item => item.productId === product.id) ? 
            'Issues Reported' : 'Available'
        }));

        setProductMetrics({
          totalProducts: Object.keys(products).length,
          productsOutOfStock: new Set(filteredOutOfStock.map(item => item.productId)).size,
          damagedProducts: filteredExpiry.filter(item => item.status === 'Damaged').length,
          expiredProducts: filteredExpiry.filter(item => item.status === 'Expired').length,
          categoriesWithIssues,
          categoryDistribution,
          productStatus
        });

        setLoading(false);
      });
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Error fetching data. Please try again later.');
      setLoading(false);
    }
  }, [filterDataByDateRange]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 p-4">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="w-full space-y-6">
      {/* Controls */}
      <div className="flex flex-wrap gap-4 justify-between items-center mb-6">
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={setDateRange}
          className="px-4 py-2 border rounded-lg"
          placeholderText="Select date range"
        />
        <div className="flex gap-4">
          <button 
            onClick={exportToExcel}
            className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
          >
            Export to Excel
          </button>
          <button 
            onClick={exportToPDF}
            className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
          >
            Export to PDF
          </button>
        </div>
      </div>

      {/* Report Content */}
      <div ref={reportRef} className="space-y-6">
        {/* Metrics Cards */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="bg-white rounded-lg shadow p-4">
            <div className="flex items-center gap-2 border-b pb-2">
              <PackageCheck className="w-5 h-5 text-blue-600" />
              <h3 className="text-lg font-semibold text-gray-700">Total Products</h3>
            </div>
            <div className="mt-4">
              <div className="text-2xl font-bold text-blue-600">
                {formatNumber(productMetrics.totalProducts)}
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow p-4">
            <div className="flex items-center gap-2 border-b pb-2">
              <AlertCircle className="w-5 h-5 text-orange-600" />
              <h3 className="text-lg font-semibold text-gray-700">Out of Stock</h3>
            </div>
            <div className="mt-4">
              <div className="text-2xl font-bold text-orange-600">
                {formatNumber(productMetrics.productsOutOfStock)}
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow p-4">
            <div className="border-b pb-2">
              <h3 className="text-lg font-semibold text-gray-700">Damaged Products</h3>
            </div>
            <div className="mt-4">
              <div className="text-2xl font-bold text-red-600">
                {formatNumber(productMetrics.damagedProducts)}
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow p-4">
            <div className="border-b pb-2">
              <h3 className="text-lg font-semibold text-gray-700">Expired Products</h3>
            </div>
            <div className="mt-4">
              <div className="text-2xl font-bold text-purple-600">
                {formatNumber(productMetrics.expiredProducts)}
              </div>
            </div>
          </div>
        </div>

        {/* Charts */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white rounded-lg shadow p-4">
            <h3 className="text-lg font-semibold text-gray-700 border-b pb-4">Category Distribution</h3>
            <div className="mt-4 h-80">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={Object.entries(productMetrics.categoryDistribution).map(([name, value]) => ({
                      name,
                      value
                    }))}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    label
                  >
                    {Object.entries(productMetrics.categoryDistribution).map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow p-4">
            <h3 className="text-lg font-semibold text-gray-700 border-b pb-4">Product Status</h3>
            <div className="mt-4 h-80">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={[
                  { status: 'Available', count: productMetrics.totalProducts - productMetrics.productsOutOfStock },
                  { status: 'Out of Stock', count: productMetrics.productsOutOfStock },
                  { status: 'Damaged', count: productMetrics.damagedProducts },
                  { status: 'Expired', count: productMetrics.expiredProducts }
                ]}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="status" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="count" fill="#3b82f6" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        {/* Table */}
        <div className="bg-white rounded-lg shadow p-4 text-left">
          <h3 className="text-lg font-semibold text-gray-700 border-b pb-4">Product Details</h3>
          <div className="mt-4 overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr className="bg-gray-50">
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Product</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Category</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {productMetrics.productStatus.map((product, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-4 py-2 whitespace-nowrap">{product.name}</td>
                    <td className="px-4 py-2 whitespace-nowrap">{product.category}</td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      <span className={`inline-flex px-2 text-xs font-semibold leading-5 rounded-full ${
                        product.status === 'Available' ? 'bg-green-100 text-green-800' :
                        product.status === 'Out of Stock' ? 'bg-red-100 text-red-800' :
                        'bg-orange-100 text-orange-800'
                      }`}>
                        {product.status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductAnalytics;
import React, { useState, useEffect, useRef } from 'react';
import { AlertCircle } from 'lucide-react';

const MapComponent = ({ stores, channelColors }) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStore, setSelectedStore] = useState(null);
  const mapRef = useRef(null);
  const markersRef = useRef([]);
  const mapInstanceRef = useRef(null);
  const scriptLoadedRef = useRef(false);

  useEffect(() => {
    const cleanup = () => {
      const existingScript = document.querySelector('script[src*="maps.googleapis.com"]');
      if (existingScript) {
        existingScript.remove();
      }
      if (mapInstanceRef.current) {
        markersRef.current.forEach(marker => marker.setMap(null));
        markersRef.current = [];
      }
    };

    const loadGoogleMaps = () => {
      if (scriptLoadedRef.current) {
        initializeMap();
        return;
      }

      cleanup();

      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=geometry`;
      script.async = true;
      script.defer = true;
      
      script.onerror = () => {
        setError('Failed to load Google Maps. Please check your API key and try again.');
        setIsLoading(false);
      };

      script.onload = () => {
        scriptLoadedRef.current = true;
        initializeMap();
      };

      document.body.appendChild(script);
    };

    const initializeMap = () => {
      try {
        if (!mapRef.current) return;

        const defaultCenter = { lat: 18.1096, lng: -77.2975 };
        
        mapInstanceRef.current = new window.google.maps.Map(mapRef.current, {
          center: defaultCenter,
          zoom: 9,
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [{ visibility: "off" }]
            }
          ]
        });

        const geocodeWithDelay = (store, delay) => {
          return new Promise((resolve) => {
            setTimeout(() => {
              const geocoder = new window.google.maps.Geocoder();
              geocoder.geocode(
                { address: `${store.searchLocation}, Jamaica` },
                (results, status) => {
                  resolve({ results, status, store });
                }
              );
            }, delay);
          });
        };

        const batchSize = 10;
        const delayBetweenBatches = 1000;

        const processStoresInBatches = async () => {
          const validStores = stores.filter(store => store.searchLocation);
          
          for (let i = 0; i < validStores.length; i += batchSize) {
            const batch = validStores.slice(i, i + batchSize);
            const promises = batch.map((store, index) => 
              geocodeWithDelay(store, index * (delayBetweenBatches / batchSize))
            );
            
            const results = await Promise.all(promises);
            
            results.forEach(({ results, status, store }) => {
              if (status === 'OK' && results[0].geometry) {
                const marker = new window.google.maps.Marker({
                  map: mapInstanceRef.current,
                  position: results[0].geometry.location,
                  title: store.storeName,
                  icon: {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    fillColor: channelColors[store.channel] || '#808080',
                    fillOpacity: 1,
                    strokeWeight: 1,
                    strokeColor: '#FFFFFF',
                    scale: 8
                  }
                });

                marker.addListener('click', () => {
                  setSelectedStore(store);
                });

                markersRef.current.push(marker);
              }
            });

            if (i + batchSize < validStores.length) {
              await new Promise(resolve => setTimeout(resolve, delayBetweenBatches));
            }
          }
          
          setIsLoading(false);
        };

        processStoresInBatches().catch(err => {
          setError('Error loading store locations. Please try again later.');
          setIsLoading(false);
        });
      } catch (err) {
        setError('Error initializing map. Please try again later.');
        setIsLoading(false);
      }
    };

    loadGoogleMaps();

    return cleanup;
  }, [stores, channelColors]);

  if (error) {
    return (
      <div className="rounded-lg border border-red-200 bg-red-50 p-4">
        <div className="flex items-center gap-2">
          <AlertCircle className="h-4 w-4 text-red-600" />
          <p className="text-sm font-medium text-red-900">Error</p>
        </div>
        <p className="mt-2 text-sm text-red-800">{error}</p>
      </div>
    );
  }

  return (
    <div className="relative w-full h-96">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50 z-10">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
        </div>
      )}
      <div ref={mapRef} className="w-full h-full rounded-lg" />
      {selectedStore && (
        <div className="absolute top-4 right-4 bg-white p-4 rounded-lg shadow-md max-w-xs">
          <button
            onClick={() => setSelectedStore(null)}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 font-bold"
            aria-label="Close"
          >
            ×
          </button>
          <h3 className="font-bold mb-2">{selectedStore.storeName}</h3>
          <p className="text-sm text-gray-600">{selectedStore.searchLocation}</p>
          <p className="text-sm text-gray-600 mt-1">
            <span className="font-medium">Channel:</span> {selectedStore.channel}
          </p>
        </div>
      )}
    </div>
  );
};

export default MapComponent;
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import { CSVLink } from "react-csv";
import { ref, onValue, remove } from 'firebase/database';
import { db } from '../firebase';
import "react-datepicker/dist/react-datepicker.css";

const MarketingActivity = () => {
  // State management with default dates
  const defaultDateRange = [new Date(), new Date()];
  const [filters, setFilters] = useState({
    dateRange: defaultDateRange,
    store: null,
    location: null,
    category: null,
    product: null,
    promotionType: null,
    merchandiser: null,
    competitorProduct: null
  });

  const [marketingData, setMarketingData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [stores, setStores] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredCompetitorProducts, setFilteredCompetitorProducts] = useState([]);

  const promotionTypes = [
    { value: 'Company Product', label: 'Company Product' },
    { value: 'Competitor Product', label: 'Competitor Product' }
  ];

  // Load initial data
  useEffect(() => {
    const loadStores = () => {
      const storesRef = ref(db, 'stores');
      onValue(storesRef, (snapshot) => {
        const data = snapshot.val();
        const storesList = data ? Object.entries(data).map(([id, store]) => ({
          value: id,
          label: store.storeName,
          parish: store.parish,
          searchLocation: store.searchLocation
        })) : [];
        setStores(storesList);
      });
    };

    const loadCategories = () => {
      const categoriesRef = ref(db, 'categories');
      onValue(categoriesRef, (snapshot) => {
        const data = snapshot.val();
        const categoriesList = data ? Object.entries(data).map(([id, category]) => ({
          value: id,
          label: category.categoryName
        })) : [];
        setCategories(categoriesList);
      });
    };

    const loadProducts = () => {
      const productsRef = ref(db, 'products');
      onValue(productsRef, (snapshot) => {
        const data = snapshot.val();
        const productsList = data ? Object.entries(data).map(([id, product]) => ({
          value: id,
          label: product.productName,
          categoryId: product.categoryId,
          productNumber: product.productNumber,
          competitorProductName: product.competitorProductName,
          storeId: product.storeId
        })) : [];
        setProducts(productsList);
      });
    };

    const loadUsers = () => {
      const usersRef = ref(db, 'users');
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
        const usersList = data ? Object.entries(data).map(([id, user]) => ({
          value: id,
          label: user.fullName,
          role: user.role
        })).filter(user => user.role === 'Merchandiser and Manager') : [];
        setUsers(usersList);
      });
    };

    Promise.all([
      loadStores(),
      loadCategories(),
      loadProducts(),
      loadUsers()
    ]).then(() => setLoading(false));
  }, []);

 
useEffect(() => {
    const marketingRef = ref(db, 'marketingActivities');
    onValue(marketingRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const activitiesList = Object.entries(data).map(([id, activity]) => {
          const product = products.find(p => p.value === activity.productId);
          const category = categories.find(c => c.value === activity.categoryId);
          const store = stores.find(s => s.value === activity.storeId);
          const merchandiser = users.find(u => u.value === activity.userId);
          
          const timestamp = activity.timestamp;
          const activityDate = new Date(timestamp);
          
          return {
            id,
            timestamp,
            date: activityDate.toLocaleDateString(),
            storeName: store?.label || '',
            location: store?.parish || '',
            searchLocation: store?.searchLocation || '',
            promotionType: activity.promotionType || '',
            categoryName: category?.label || '',
            productName: product?.label || '',
            productNumber: product?.productNumber || '',
            competitorProductName: product?.competitorProductName || '', // Make sure this is included
            description: activity.description || '',
            photoUrl: activity.photoUrl || '',
            merchandiserName: merchandiser?.label || ''
          };
        });
        setMarketingData(activitiesList);
      }
      setLoading(false);
    });
  }, [categories, products, stores, users]);

  // Filter handlers
  const handleStoreChange = (selectedStore) => {
    setFilters(prev => ({ ...prev, store: selectedStore, location: null }));
    if (selectedStore) {
      const storeLocations = stores
        .filter(store => store.value === selectedStore.value)
        .map(store => ({
          value: store.parish,
          label: store.parish
        }));
      setFilteredLocations(storeLocations);
    } else {
      setFilteredLocations([]);
    }
  };

  const handleDelete = async (id) => {
    try {
      if (window.confirm('Are you sure you want to delete this record?')) {
        const activityRef = ref(db, `marketingActivities/${id}`);
        await remove(activityRef);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
      alert('Failed to delete record. Please try again.');
    }
  };


  const handleCategoryChange = (selectedCategory) => {
    setFilters(prev => ({ ...prev, category: selectedCategory, product: null, competitorProduct: null }));
    if (selectedCategory) {
      const categoryProducts = products
        .filter(product => product.categoryId === selectedCategory.value)
        .map(product => ({
          value: product.value,
          label: product.label,
          competitorProductName: product.competitorProductName
        }));
      setFilteredProducts(categoryProducts);
      
      const competitorProducts = [...new Set(categoryProducts
        .map(product => product.competitorProductName)
        .filter(Boolean))]
        .map(name => ({ value: name, label: name }));
      setFilteredCompetitorProducts(competitorProducts);
    } else {
      setFilteredProducts([]);
      setFilteredCompetitorProducts([]);
    }
  };

  // Get filtered data with safe date handling
  const getFilteredData = () => {
    return marketingData.filter(row => {
      try {
        // Date Range Filter with safe handling
        const rowDate = new Date(row.timestamp);
        const startDate = new Date(filters.dateRange[0] || defaultDateRange[0]);
        const endDate = new Date(filters.dateRange[1] || defaultDateRange[1]);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        
        const dateInRange = rowDate >= startDate && rowDate <= endDate;
        
        // Other filters with null checks
        const storeMatch = !filters.store || row.storeName === filters.store.label;
        const locationMatch = !filters.location || row.location === filters.location.value;
        const categoryMatch = !filters.category || row.categoryName === filters.category.label;
        const productMatch = !filters.product || row.productName === filters.product.label;
        const promotionMatch = !filters.promotionType || row.promotionType === filters.promotionType.value;
        const merchandiserMatch = !filters.merchandiser || row.merchandiserName === filters.merchandiser.label;
        const competitorMatch = !filters.competitorProduct || row.competitorProductName === filters.competitorProduct.value;

        return (
          dateInRange &&
          storeMatch &&
          locationMatch &&
          categoryMatch &&
          productMatch &&
          promotionMatch &&
          merchandiserMatch &&
          competitorMatch
        );
      } catch (error) {
        console.error('Error filtering row:', error);
        return false;
      }
    });
  };

  const filteredMarketingData = getFilteredData();

  // PDF Styles
  const styles = StyleSheet.create({
    page: {
      padding: 30,
      orientation: 'landscape'
    },
    title: {
      fontSize: 18,
      marginBottom: 20,
      textAlign: 'center'
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0
    },
    tableRow: {
      flexDirection: 'row'
    },
    tableColHeader: {
      width: '10%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      padding: 5,
      backgroundColor: '#f3f4f6',
      fontSize: 10
    },
    tableCol: {
      width: '10%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      padding: 5,
      fontSize: 9
    }
  });

  // PDF Document Component
  const MyDocument = () => (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <Text style={styles.title}>Marketing Activity Report</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            {['Date', 'Store', 'Location', 'Type', 'Category', 'Product', 'SKU', 'Competitor', 'Description', 'Merchandiser'].map((header) => (
              <View key={header} style={styles.tableColHeader}>
                <Text>{header}</Text>
              </View>
            ))}
          </View>
          {filteredMarketingData.map((row, i) => (
            <View key={i} style={styles.tableRow}>
              <View style={styles.tableCol}><Text>{row.date}</Text></View>
              <View style={styles.tableCol}><Text>{row.storeName}</Text></View>
              <View style={styles.tableCol}><Text>{row.location}</Text></View>
              <View style={styles.tableCol}><Text>{row.promotionType}</Text></View>
              <View style={styles.tableCol}><Text>{row.categoryName}</Text></View>
              <View style={styles.tableCol}><Text>{row.productName}</Text></View>
              <View style={styles.tableCol}><Text>{row.productNumber}</Text></View>
              <View style={styles.tableCol}><Text>{row.competitorProductName}</Text></View>
              <View style={styles.tableCol}><Text>{row.description}</Text></View>
              <View style={styles.tableCol}><Text>{row.merchandiserName}</Text></View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );

  // Excel download handler
  const handleExcelDownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredMarketingData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Marketing Activity");
    XLSX.writeFile(workbook, "marketing_activity.xlsx");
  };

  // DatePicker custom input with safe date handling
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => {
    try {
      const start = filters.dateRange[0] || defaultDateRange[0];
      const end = filters.dateRange[1] || defaultDateRange[1];
      const displayValue = `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`;
      return (
        <button 
          className="w-full px-4 py-2 text-left bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500" 
          onClick={onClick} 
          ref={ref}
        >
          {displayValue}
        </button>
      );
    } catch (error) {
      console.error('Error in CustomInput:', error);
      return null;
    }
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 bg-white rounded-lg shadow-lg">
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Marketing Activity</h1>
      </div>
      <div className="space-y-6">
        {/* Filters */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          <div className="lg:col-span-4">
            <DatePicker
              selected={filters.dateRange[0] || defaultDateRange[0]}
              onChange={(dates) => {
                const [start, end] = dates;
                setFilters(prev => ({
                  ...prev,
                  dateRange: [
                    start || defaultDateRange[0],
                    end || defaultDateRange[1]
                  ]
                }));
              }}
              startDate={filters.dateRange[0] || defaultDateRange[0]}
              endDate={filters.dateRange[1] || defaultDateRange[1]}
              selectsRange
              customInput={<CustomInput />}
            />
          </div>
          
          <Select
            value={filters.store}
            onChange={handleStoreChange}
            options={stores}
            isClearable
            placeholder="Select Store"
            className="react-select-container"
            classNamePrefix="react-select"
          />
          
          <Select
            value={filters.location}
            onChange={(location) => setFilters(prev => ({ ...prev, location }))}
            options={filteredLocations}
            isClearable
            placeholder="Select Location"
            isDisabled={!filters.store}
            className="react-select-container"
            classNamePrefix="react-select"
          />
          
          <Select
            value={filters.category}
            onChange={handleCategoryChange}
            options={categories}
            isClearable
            placeholder="Select Category"
            className="react-select-container"
            classNamePrefix="react-select"
          />
          
          <Select
            value={filters.product}
            onChange={(product) => setFilters(prev => ({ ...prev, product }))}
            options={filteredProducts}
            isClearable
            placeholder="Select Product"
            isDisabled={!filters.category}
            className="react-select-container"
            classNamePrefix="react-select"
          />
          
          <Select
           value={filters.promotionType}
           onChange={(type) => setFilters(prev => ({ ...prev, promotionType: type }))}
           options={promotionTypes}
           isClearable
           placeholder="Select Promotion Type"
           className="react-select-container"
           classNamePrefix="react-select"
         />
         
         <Select
           value={filters.merchandiser}
           onChange={(merchandiser) => setFilters(prev => ({ ...prev, merchandiser }))}
           options={users}
           isClearable
           placeholder="Select Merchandiser"
           className="react-select-container"
           classNamePrefix="react-select"
         />
         
         <Select
           value={filters.competitorProduct}
           onChange={(competitor) => setFilters(prev => ({ ...prev, competitorProduct: competitor }))}
           options={filteredCompetitorProducts}
           isClearable
           placeholder="Select Competitor Product"
           isDisabled={!filters.category}
           className="react-select-container"
           classNamePrefix="react-select"
         />
       </div>

       {/* Data Table */}
       <div className="bg-white rounded-lg shadow-md">
         <div className="flex justify-between items-center p-4 border-b">
           <h2 className="text-xl font-semibold text-gray-900">
             Marketing Activity Details ({filteredMarketingData.length} records)
           </h2>
           <div className="space-x-2">
             <PDFDownloadLink document={<MyDocument />} fileName="marketing_activity_report.pdf">
               {({ loading }) => (
                 <button className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition-colors">
                   {loading ? 'Loading PDF...' : 'Download PDF'}
                 </button>
               )}
             </PDFDownloadLink>
             <button 
               onClick={handleExcelDownload} 
               className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded transition-colors"
             >
               Download Excel
             </button>
             <CSVLink 
               data={filteredMarketingData} 
               filename="marketing_activity.csv"
               className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded inline-block transition-colors"
             >
               Download CSV
             </CSVLink>
           </div>
         </div>

         <div className="overflow-x-auto">
           <table className="min-w-full divide-y divide-gray-200">
             <thead className="bg-gray-50">
               <tr>
                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                   Date
                 </th>
                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                   Store Name
                 </th>
                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                   Location
                 </th>
                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                   Type of Promotion
                 </th>
                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                   Category
                 </th>
                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                   Product Name
                 </th>
                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                   Product Number/SKU
                 </th>
                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                   Competitor Product Name
                 </th>
                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                   Photo
                 </th>
                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                   Description
                 </th>
                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                   Merchandiser
                 </th>
                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                   Action
                 </th>
               </tr>
             </thead>
             <tbody className="bg-white divide-y divide-gray-200">
               {filteredMarketingData.length === 0 ? (
                 <tr>
                   <td colSpan="12" className="px-6 py-4 text-center text-gray-500">
                     No records found matching the selected filters
                   </td>
                 </tr>
               ) : (
                 filteredMarketingData.map((row) => (
                   <tr key={row.id} className="hover:bg-gray-50">
                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                       {row.date}
                     </td>
                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                       {row.storeName}
                     </td>
                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                       {row.location}
                     </td>
                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                       {row.promotionType}
                     </td>
                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                       {row.categoryName}
                     </td>
                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                       {row.productName}
                     </td>
                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                       {row.productNumber}
                     </td>
                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                       {row.competitorProductName}
                     </td>
                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                       {row.photoUrl && (
                         <img 
                           src={row.photoUrl} 
                           alt="Marketing Activity" 
                           className="w-16 h-16 object-cover rounded cursor-pointer hover:opacity-75 transition-opacity"
                           onClick={() => window.open(row.photoUrl, '_blank')}
                         />
                       )}
                     </td>
                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                       {row.description}
                     </td>
                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                       {row.merchandiserName}
                     </td>
                     <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
    <button 
      className="text-red-600 hover:text-red-900 px-3 py-1 rounded-md border border-red-600 hover:border-red-900 transition-colors"
      onClick={() => handleDelete(row.id)}
    >
      Delete
    </button>
  </td>
                   </tr>
                 ))
               )}
             </tbody>
           </table>
         </div>
       </div>
     </div>
   </div>
 );
};

export default MarketingActivity;
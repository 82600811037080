import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getDatabase, ref, onValue } from 'firebase/database';

const UNITS_PER_CASE = 24;

const StockAnalytics = () => {
  // State management
  const [stockMetrics, setStockMetrics] = useState({
    totalStock: { cases: 0, units: 0 },
    openingStock: { cases: 0, units: 0 },
    closingStock: { cases: 0, units: 0 },
    averageStock: { cases: 0, units: 0 }
  });
  const [stockDistribution, setStockDistribution] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Helper function to convert between units and cases
  const getValueInUnits = (value, unitType) => {
    const numericValue = parseFloat(value) || 0;
    return unitType === 'Cases' ? numericValue * UNITS_PER_CASE : numericValue;
  };

  const getValueInCases = (value, unitType) => {
    const numericValue = parseFloat(value) || 0;
    return unitType === 'Units' ? numericValue / UNITS_PER_CASE : numericValue;
  };

  // Helper function to calculate total stocks from a stock entry
  const calculateTotalStocks = (item) => {
    if (!item) return { units: 0, cases: 0 };

    const shelfUnits = getValueInUnits(item.stockOnShelf, item.stockOnShelfUnit);
    const shelfCases = getValueInCases(item.stockOnShelf, item.stockOnShelfUnit);
    const packedUnits = getValueInUnits(item.stocksPacked, item.stocksPackedUnit);
    const packedCases = getValueInCases(item.stocksPacked, item.stocksPackedUnit);
    const storeUnits = getValueInUnits(item.stocksInStore, item.stocksInStoreUnit);
    const storeCases = getValueInCases(item.stocksInStore, item.stocksInStoreUnit);

    return {
      units: shelfUnits + packedUnits + storeUnits,
      cases: shelfCases + packedCases + storeCases
    };
  };

  // Format numbers with commas
  const formatNumber = (number, decimals = 0) => {
    if (number === null || number === undefined || isNaN(number)) {
      return '0';
    }
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    }).format(parseFloat(number));
  };

  // Fetch data from Firebase
  useEffect(() => {
    const db = getDatabase();
    setLoading(true);
    setError(null);

    try {
      const stockRef = ref(db, 'stock');
      const productsRef = ref(db, 'products');

      onValue(productsRef, (productsSnapshot) => {
        if (!productsSnapshot.exists()) {
          setError('No products found in database');
          setLoading(false);
          return;
        }

        const products = productsSnapshot.val();

        onValue(stockRef, (stockSnapshot) => {
          if (!stockSnapshot.exists()) {
            setError('No stock data found in database');
            setLoading(false);
            return;
          }

          const stockData = stockSnapshot.val();
          const stockEntries = Object.values(stockData);

          // Calculate stock by product
          const stockByProduct = {};
          stockEntries.forEach(stock => {
            const productId = stock.productId;
            const product = products[productId];
            const productName = product ? product.productName : 'Unknown Product';
            const stockTotals = calculateTotalStocks(stock);

            if (!stockByProduct[productId]) {
              stockByProduct[productId] = {
                name: productName,
                units: 0,
                cases: 0
              };
            }

            stockByProduct[productId].units += stockTotals.units;
            stockByProduct[productId].cases += stockTotals.cases;
          });

          // Sort entries by timestamp for opening/closing stock
          const sortedEntries = [...stockEntries].sort((a, b) => 
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
          );

          // Calculate metrics
          const openingStock = sortedEntries[0] ? 
            calculateTotalStocks(sortedEntries[0]) : 
            { units: 0, cases: 0 };

          const closingStock = sortedEntries[sortedEntries.length - 1] ? 
            calculateTotalStocks(sortedEntries[sortedEntries.length - 1]) : 
            { units: 0, cases: 0 };

          const totalStock = Object.values(stockByProduct).reduce(
            (acc, curr) => ({
              units: acc.units + curr.units,
              cases: acc.cases + curr.cases
            }), 
            { units: 0, cases: 0 }
          );

          const averageStock = {
            units: totalStock.units / (sortedEntries.length || 1),
            cases: totalStock.cases / (sortedEntries.length || 1)
          };

          setStockMetrics({
            totalStock,
            openingStock,
            closingStock,
            averageStock
          });

          setStockDistribution(Object.values(stockByProduct));
          setLoading(false);
        });
      });
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Error fetching data. Please try again later.');
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 p-4">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="w-full space-y-8 p-6 bg-white rounded-lg">
      <div className="text-center space-y-2">
        <h1 className="text-2xl font-bold">Stock Analytics Report</h1>
        <p className="text-gray-600">Period: {new Date().toLocaleDateString()}</p>
      </div>

      {/* Metrics Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="border-b pb-2">
            <h3 className="text-lg font-bold text-gray-700">Total Stock</h3>
          </div>
          <div className="mt-4">
            <div className="text-2xl font-bold text-blue-600">
              {formatNumber(stockMetrics.totalStock.cases)} Cases
            </div>
            <div className="text-gray-600">
              {formatNumber(stockMetrics.totalStock.units)} Units
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="border-b pb-2">
            <h3 className="text-lg font-bold text-gray-700">Opening Stock</h3>
          </div>
          <div className="mt-4">
            <div className="text-2xl font-bold text-green-600">
              {formatNumber(stockMetrics.openingStock.cases)} Cases
            </div>
            <div className="text-gray-600">
              {formatNumber(stockMetrics.openingStock.units)} Units
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="border-b pb-2">
            <h3 className="text-lg font-bold text-gray-700">Closing Stock</h3>
          </div>
          <div className="mt-4">
            <div className="text-2xl font-bold text-purple-600">
              {formatNumber(stockMetrics.closingStock.cases)} Cases
            </div>
            <div className="text-gray-600">
              {formatNumber(stockMetrics.closingStock.units)} Units
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="border-b pb-2">
            <h3 className="text-lg font-bold text-gray-700">Average Stock</h3>
          </div>
          <div className="mt-4">
            <div className="text-2xl font-bold text-orange-600">
              {formatNumber(stockMetrics.averageStock.cases, 1)} Cases
            </div>
            <div className="text-gray-600">
              {formatNumber(stockMetrics.averageStock.units, 1)} Units
            </div>
          </div>
        </div>
      </div>

      {/* Stock Distribution Chart */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="border-b pb-4">
          <h3 className="text-lg font-bold text-gray-700">Stock Distribution by Product</h3>
        </div>
        <div className="mt-4 h-80">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={stockDistribution}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="name" 
                angle={-45} 
                textAnchor="end" 
                height={80}
                interval={0}
                tick={{fontSize: 12}}
              />
              <YAxis 
                yAxisId="left"
                orientation="left"
                label={{ 
                  value: 'Units', 
                  angle: -90, 
                  position: 'insideLeft',
                  style: { textAnchor: 'middle' }
                }}
              />
              <YAxis 
                yAxisId="right"
                orientation="right"
                label={{ 
                  value: 'Cases', 
                  angle: 90, 
                  position: 'insideRight',
                  style: { textAnchor: 'middle' }
                }}
              />
              <Tooltip formatter={(value) => formatNumber(value)} />
              <Legend />
              <Bar 
                yAxisId="left"
                dataKey="units" 
                fill="#3b82f6" 
                name="Units"
                radius={[4, 4, 0, 0]}
              />
              <Bar 
                yAxisId="right"
                dataKey="cases" 
                fill="#10b981" 
                name="Cases"
                radius={[4, 4, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default StockAnalytics;
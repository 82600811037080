import React, { useState, useEffect, useCallback } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { format } from 'date-fns';
import { Download, FileText, Trash2 } from 'lucide-react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getDatabase, ref, onValue, remove } from 'firebase/database';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const ProductExpiryDamageTracker = () => {
  // State management
  const [expiryData, setExpiryData] = useState([]);
  const [stores, setStores] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Filters state
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  // Statistics state
  const [storeStats, setStoreStats] = useState([]);
  const [categoryStats, setCategoryStats] = useState([]);
  const [productStats, setProductStats] = useState([]);

  // Helper functions
  const getStoreName = useCallback((storeId) => 
    stores.find(store => store.id === storeId)?.storeName || 'Unknown'
  , [stores]);

  const getStoreLocation = useCallback((storeId) => 
    stores.find(store => store.id === storeId)?.parish || 'Unknown'
  , [stores]);

  const getCategoryName = useCallback((categoryId) =>
    categories.find(category => category.id === categoryId)?.categoryName || 'Unknown'
  , [categories]);

  const getProductName = useCallback((productId) =>
    products.find(product => product.id === productId)?.productName || 'Unknown'
  , [products]);

  const getProductNumber = useCallback((productId) =>
    products.find(product => product.id === productId)?.productNumber || 'N/A'
  , [products]);

  const getUserName = useCallback((userId) =>
    users.find(user => user.id === userId)?.fullName || 'Unknown'
  , [users]);

  // Calculate statistics using useCallback
  const calculateStats = useCallback((data) => {
    // Store statistics
    const storeData = data.reduce((acc, item) => {
      const storeName = getStoreName(item.storeId);
      if (!acc[storeName]) {
        acc[storeName] = { damaged: 0, expired: 0, total: 0 };
      }
      acc[storeName][item.status.toLowerCase()]++;
      acc[storeName].total++;
      return acc;
    }, {});

    // Category statistics
    const categoryData = data.reduce((acc, item) => {
      const categoryName = getCategoryName(item.categoryId);
      if (!acc[categoryName]) {
        acc[categoryName] = { damaged: 0, expired: 0, total: 0 };
      }
      acc[categoryName][item.status.toLowerCase()]++;
      acc[categoryName].total++;
      return acc;
    }, {});

    // Product statistics
    const productData = data.reduce((acc, item) => {
      const productName = getProductName(item.productId);
      if (!acc[productName]) {
        acc[productName] = { damaged: 0, expired: 0, total: 0 };
      }
      acc[productName][item.status.toLowerCase()]++;
      acc[productName].total++;
      return acc;
    }, {});

    setStoreStats(Object.entries(storeData).map(([name, stats]) => ({
      name,
      ...stats
    })));

    setCategoryStats(Object.entries(categoryData).map(([name, stats]) => ({
      name,
      ...stats
    })));

    setProductStats(Object.entries(productData).map(([name, stats]) => ({
      name,
      ...stats
    })));
  }, [getStoreName, getCategoryName, getProductName]);

  // Data fetching
  useEffect(() => {
    const db = getDatabase();
    setLoading(true);
    setError(null);

    const references = {
      productExpiry: ref(db, 'productExpiry'),
      stores: ref(db, 'stores'),
      categories: ref(db, 'categories'),
      products: ref(db, 'products'),
      users: ref(db, 'users')
    };

    const unsubscribe = {};

    try {
      // Fetch stores
      unsubscribe.stores = onValue(references.stores, (snapshot) => {
        if (snapshot.exists()) {
          const storesData = Object.entries(snapshot.val()).map(([id, data]) => ({
            id,
            ...data
          }));
          setStores(storesData);
        }
      });

      // Fetch categories
      unsubscribe.categories = onValue(references.categories, (snapshot) => {
        if (snapshot.exists()) {
          const categoriesData = Object.entries(snapshot.val()).map(([id, data]) => ({
            id,
            ...data
          }));
          setCategories(categoriesData);
        }
      });

      // Fetch products
      unsubscribe.products = onValue(references.products, (snapshot) => {
        if (snapshot.exists()) {
          const productsData = Object.entries(snapshot.val()).map(([id, data]) => ({
            id,
            ...data
          }));
          setProducts(productsData);
        }
      });

      // Fetch users
      unsubscribe.users = onValue(references.users, (snapshot) => {
        if (snapshot.exists()) {
          const usersData = Object.entries(snapshot.val()).map(([id, data]) => ({
            id,
            ...data
          }));
          setUsers(usersData);
        }
      });

      // Fetch product expiry data
      unsubscribe.productExpiry = onValue(references.productExpiry, (snapshot) => {
        if (snapshot.exists()) {
          const expiryData = Object.entries(snapshot.val()).map(([id, data]) => ({
            id,
            ...data,
            timestamp: new Date(data.timestamp)
          }));
          setExpiryData(expiryData);
          calculateStats(expiryData);
        }
      });

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load data. Please try again later.');
      setLoading(false);
    }

    // Cleanup
    return () => {
      Object.values(unsubscribe).forEach(unsub => unsub && unsub());
    };
  }, [calculateStats]);

  // Filter data
  const filteredData = expiryData.filter(item => {
    const matchesDate = !startDate || !endDate || 
      (item.timestamp >= startDate && item.timestamp <= endDate);
    const matchesStore = !selectedStore || item.storeId === selectedStore.value;
    const matchesCategory = !selectedCategory || item.categoryId === selectedCategory.value;
    const matchesProduct = !selectedProduct || item.productId === selectedProduct.value;
    const matchesStatus = !selectedStatus || item.status === selectedStatus.value;

    return matchesDate && matchesStore && matchesCategory && 
           matchesProduct && matchesStatus;
  });

  // Handle delete
  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this record?')) {
      const db = getDatabase();
      try {
        await remove(ref(db, `productExpiry/${id}`));
      } catch (error) {
        console.error('Error deleting record:', error);
        alert('Failed to delete record. Please try again.');
      }
    }
  };

  // Export functions
  const exportToExcel = () => {
    const exportData = filteredData.map(item => ({
      'Date': format(item.timestamp, 'MM/dd/yyyy'),
      'Store': getStoreName(item.storeId),
      'Location': getStoreLocation(item.storeId),
      'Category': getCategoryName(item.categoryId),
      'Product': getProductName(item.productId),
      'SKU': getProductNumber(item.productId),
      'Status': item.status,
      'Units': item.amountUnits,
      'Cases': item.amountCases,
      'Batch #': item.batchNumber,
      'Expiry Date': format(new Date(item.expiryDate), 'MM/dd/yyyy'),
      'Action': item.action,
      'Merchandiser': getUserName(item.userId)
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Product Expiry');
    XLSX.writeFile(wb, 'product_expiry_report.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF('l', 'pt');
    
    doc.setFontSize(18);
    doc.text('Product Expiry & Damage Report', 40, 40);

    const tableColumns = [
      'Date', 'Store', 'Location', 'Category', 'Product', 'SKU',
      'Status', 'Units', 'Cases', 'Batch #', 'Expiry Date', 'Action',
      'Merchandiser'
    ];

    const tableRows = filteredData.map(item => [
      format(item.timestamp, 'MM/dd/yyyy'),
      getStoreName(item.storeId),
      getStoreLocation(item.storeId),
      getCategoryName(item.categoryId),
      getProductName(item.productId),
      getProductNumber(item.productId),
      item.status,
      item.amountUnits,
      item.amountCases,
      item.batchNumber,
      format(new Date(item.expiryDate), 'MM/dd/yyyy'),
      item.action,
      getUserName(item.userId)
    ]);

    doc.autoTable({
      head: [tableColumns],
      body: tableRows,
      startY: 60,
      styles: { fontSize: 8, cellPadding: 2 },
      margin: { top: 60 }
    });

    doc.save('product_expiry_report.pdf');
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Product Expiry & Damage Tracker</h1>

      {/* Filters */}
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-6">
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={setDateRange}
          className="w-full px-4 py-2 border rounded"
          placeholderText="Select Date Range"
        />
        
        <Select
          value={selectedStore}
          onChange={setSelectedStore}
          options={stores.map(store => ({
            value: store.id,
            label: store.storeName
          }))}
          placeholder="Select Store"
          isClearable
        />

        <Select
          value={selectedCategory}
          onChange={setSelectedCategory}
          options={categories.map(category => ({
            value: category.id,
            label: category.categoryName
          }))}
          placeholder="Select Category"
          isClearable
        />

        <Select
          value={selectedProduct}
          onChange={setSelectedProduct}
          options={products.map(product => ({
            value: product.id,
            label: product.productName
          }))}
          placeholder="Select Product"
          isClearable
        />

        <Select
          value={selectedStatus}
          onChange={setSelectedStatus}
          options={[
            { value: 'Damaged', label: 'Damaged' },
            { value: 'Expired', label: 'Expired' }
          ]}
          placeholder="Select Status"
          isClearable
        />
      </div>

      {/* Export Buttons */}
      <div className="flex gap-4 mb-6">
        <button
          onClick={exportToExcel}
          className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded flex items-center gap-2"
        >
          <Download className="h-5 w-5" />
          Export to Excel
        </button>
        <button
          onClick={exportToPDF}
          className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded flex items-center gap-2"
        >
          <FileText className="h-5 w-5" />
          Export to PDF
        </button>
      </div>

      {/* Statistics Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        {/* Stores Stats */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-lg font-semibold mb-4">Stores with Damaged/Expired Products</h2>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={storeStats}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="damaged" fill="#ef4444" name="Damaged" />
                <Bar dataKey="expired" fill="#f59e0b" name="Expired" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Categories Stats */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-lg font-semibold mb-4">Categories with Damaged/Expired Products</h2>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={categoryStats}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="damaged" fill="#ef4444" name="Damaged" />
                <Bar dataKey="expired" fill="#f59e0b" name="Expired" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Products Stats */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-lg font-semibold mb-4">Damaged/Expired Products</h2>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={productStats}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="damaged" fill="#ef4444" name="Damaged" />
                <Bar dataKey="expired" fill="#f59e0b" name="Expired" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Data Table */}
        <div className="bg-white p-6 rounded-lg shadow-lg col-span-3">
          <h2 className="text-lg font-semibold mb-4">Expiry & Damage Records</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Store Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">SKU</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Units</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cases</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Batch #</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Expiry Date</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Photo</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Merchandiser</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredData.map((item) => (
                  <tr key={item.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {format(item.timestamp, 'MM/dd/yyyy')}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {getStoreName(item.storeId)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {getStoreLocation(item.storeId)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {getCategoryName(item.categoryId)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {getProductName(item.productId)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {getProductNumber(item.productId)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                        item.status === 'Damaged' 
                          ? 'bg-red-100 text-red-800' 
                          : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {item.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.amountUnits}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.amountCases}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.batchNumber}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {format(new Date(item.expiryDate), 'MM/dd/yyyy')}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                        item.action === 'Dispose' 
                          ? 'bg-red-100 text-red-800'
                          : item.action === 'Return to Warehouse' 
                            ? 'bg-blue-100 text-blue-800'
                            : 'bg-green-100 text-green-800'
                      }`}>
                        {item.action}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.photoUrl && (
                        <div className="relative group">
                          <img 
                            src={item.photoUrl} 
                            alt="Product" 
                            className="h-10 w-10 rounded-full object-cover cursor-pointer"
                            onClick={() => window.open(item.photoUrl, '_blank')}
                          />
                          <div className="hidden group-hover:block absolute z-10 bg-black bg-opacity-75 text-white p-2 rounded text-xs -bottom-8 left-1/2 transform -translate-x-1/2">
                            Click to view
                          </div>
                        </div>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {getUserName(item.userId)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      <button
                        onClick={() => handleDelete(item.id)}
                        className="text-red-600 hover:text-red-900 flex items-center gap-1"
                      >
                        <Trash2 className="h-4 w-4" />
                        <span>Delete</span>
                      </button>
                    </td>
                  </tr>
                ))}
                {filteredData.length === 0 && (
                  <tr>
                    <td colSpan="15" className="px-6 py-4 text-center text-gray-500">
                      No records found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductExpiryDamageTracker;
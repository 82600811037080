import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { 
  Home, 
  BarChart2,
  Clock,
  Package,
  AlertTriangle,
  DollarSign,
  Layout,
  AlertOctagon,
  Megaphone,
  Lightbulb,
  // Bell,
  // Send,
  LogOut,
  X,
  FileText
} from 'lucide-react';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Redirect to login page will be handled by the ProtectedRoute in App.js
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const navItems = [
    { path: '/dashboard', name: 'Dashboard', icon: Home },
    { path: '/business-overview', name: 'Business Overview', icon: BarChart2 },
    { path: '/merchandiser-timesheet', name: 'Time Sheet', icon: Clock },
    { path: '/stock-count', name: 'Stock Count', icon: Package },
    { path: '/out-of-stock', name: 'Out of Stock', icon: AlertTriangle },
    { path: '/price-audit', name: 'Price Audit', icon: DollarSign },
    { path: '/planogram-compliance', name: 'Planogram Compliance', icon: Layout },
    { path: '/product-expiry-damage', name: 'Product Expiry/Damage', icon: AlertOctagon },
    { path: '/marketing-activity', name: 'Marketing Activity', icon: Megaphone },
    { path: '/opportunity-threat', name: 'Opportunity/Threat', icon: Lightbulb },
    // { path: '/notifications', name: 'Notifications', icon: Bell },
    // { path: '/send-notification', name: 'Send Notification', icon: Send },
    { path: '/retail-analytics', name: 'Retail Analytics', icon: FileText },
  ];

  return (
    <>
      <div
        className={`${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } fixed inset-y-0 left-0 z-30 w-64 bg-gray-800 transition duration-300 ease-in-out transform lg:translate-x-0 lg:static lg:inset-0`}
      >
        <div className="flex items-center justify-between h-16 px-6 bg-gray-900 lg:hidden">
          <h2 className="text-2xl font-bold text-white">Menu</h2>
          <button onClick={toggleSidebar} className="text-gray-500 focus:outline-none focus:text-gray-600">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="px-4 py-6">
          <h2 className="text-xl font-bold text-white mb-6 hidden lg:block">Smerch Customer Portal</h2>
          <nav>
            <ul className="space-y-2">
              {navItems.map((item) => (
                <li key={item.path}>
                  <Link
                    to={item.path}
                    className={`flex items-center px-4 py-2 text-sm ${
                      location.pathname === item.path
                        ? 'bg-gray-700 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                    } rounded-md transition-colors duration-200`}
                    onClick={() => window.innerWidth < 1024 && toggleSidebar()}
                  >
                    <item.icon className="w-5 h-5 mr-3" />
                    <span>{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="absolute bottom-0 w-full">
          <button
            onClick={handleLogout}
            className="flex items-center w-full px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white transition-colors duration-200"
          >
            <LogOut className="w-5 h-5 mr-3" />
            <span>Logout</span>
          </button>
        </div>
      </div>
      {isOpen && (
        <div
          className="fixed inset-0 z-20 bg-black opacity-50 transition-opacity lg:hidden"
          onClick={toggleSidebar}
        ></div>
      )}
    </>
  );
};

export default Sidebar;